<template>
  <div>
    <SpreedlyPageTitle
      :title="`${id} ${$t('details')}`"
      breadcrumbs
      :tabs="!!txn?.token"
    >
      <template v-slot:breadcrumbs>
        <router-link
          :to="{ name: 'TransactionList' }"
          id="back-to-txn-list-button"
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
        >
          <i class="pi pi-arrow-left relative mr-1 pb-4 text-[0.75rem]"></i>
          {{ $t("transactions.transactions") }}
        </router-link>
      </template>
      <template v-slot:nav-tabs>
        <button
          class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
          :class="{
            '!text-spreedly-blue-600 !bg-spreedly-blue-300':
              $route.name === 'TransactionDetailSummary',
          }"
          :disabled="txn && txn.status === 'Error'"
          @click="router.push({ name: 'TransactionDetailSummary' })"
        >
          {{ $t("summary") }}
        </button>
        <button
          class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
          :class="{
            '!text-spreedly-blue-600 !bg-spreedly-blue-300':
              $route.name === 'TransactionDetailJson',
          }"
          :disabled="txn && txn.status === 'Error'"
          @click="router.push({ name: 'TransactionDetailJson' })"
        >
          JSON
        </button>
        <button
          class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
          :class="{
            '!text-spreedly-blue-600 !bg-spreedly-blue-300':
              $route.name === 'TransactionDetailTranscript',
          }"
          :disabled="txn && txn.status === 'Error'"
          @click="router.push({ name: 'TransactionDetailTranscript' })"
        >
          {{ $t("transcript") }}
        </button>
      </template>
    </SpreedlyPageTitle>
    <router-view v-if="txn" :txn="txn" />
  </div>
</template>
<script lang="ts" setup>
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import { onMounted, watch, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useSettingsStore } from "@/stores/SettingsStore";
import { fetchTransaction } from "@/services/TransactionService";
import i18n from "@/i18n";

const router = useRouter();

const store = useSettingsStore();
const { currentOrganization, currentEnvironment } = storeToRefs(store);
const txn = ref();
const ignoreOrgOrEnvChange = ref(false);

const props = defineProps<{
  id: string;
}>();

onMounted(() => {
  getTransaction();
});

watch(
  () => props.id,
  () => {
    getTransaction();
  }
);
async function getTransaction() {
  const cachedTxn = localStorage.getItem(`transaction-token-${props.id}`);
  if (cachedTxn) {
    txn.value = JSON.parse(cachedTxn);
    localStorage.removeItem(`transaction-token-${props.id}`);
  } else {
    txn.value = await fetchTransaction(
      currentOrganization.value.key,
      currentEnvironment.value.key as string,
      props.id
    ).catch(() => {
      return {
        status: i18n.global.t("error"),
        message: i18n.global.t("notFoundInOrg"),
      };
    });

    if (txn.value?.token) {
      ignoreOrgOrEnvChange.value = true;
      await store.reconcileCurrentEnvironment(
        txn.value.environment_key,
        txn.value.organization_key
      );
      ignoreOrgOrEnvChange.value = false;
    }
  }
}

watch(
  () => currentEnvironment.value.key,
  () => {
    if (!ignoreOrgOrEnvChange.value) {
      router.push({
        name: "TransactionList",
      });
    }
  }
);
</script>
