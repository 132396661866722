<template>
  <form name="ruleEditor">
    <div v-if="modelValue" class="space-y-6">
      <div class="field">
        <label
          :class="{ 'p-error': v$.name.$errors.length > 0 }"
          for="ruleBeingEdited-name"
        >
          {{ $t("name") }}
        </label>
        <div>
          <InputText
            v-model="modelValue.name"
            :placeholder="$t('routing.rule.placeholderName')"
            :id="'ruleBeingEdited-name'"
            @keydown.enter.prevent
          />
          <SpreedlyErrorMessage
            v-if="v$.name.$errors"
            :id="'ruleBeingEdited-name-error'"
            :errors="v$.name.$errors"
          />
        </div>
      </div>
      <div>
        <h3 class="text-spreedly-gray-700 text-xl lg:text-1xl my-2 mt-0">
          {{ $t("gateway", 2) }}
        </h3>
        <GatewaySplit v-model="modelValue.targets" />
        <Message
          v-if="displaySplitRetryMismatch"
          severity="warn"
          :closable="false"
        >
          <code>{{ $t("routing.gateway.standard") }}</code>
          {{ $t("routing.gateway.splitRetryModeMismatch") }}
          <br />
          {{ $t("routing.gateway.autoSetToOutage") }}
          <code>{{ $t("routing.gateway.outageOnly") }}</code>
          {{ $t("routing.gateway.mode") }}.
        </Message>
      </div>
      <div>
        <h3 class="text-spreedly-gray-700 text-xl lg:text-1xl my-2 mt-0">
          {{ $t("retry") }}
        </h3>
        <Retry v-model="modelValue.retry" :target-data="modelValue.targets" />
      </div>
      <div
        :class="
          dimensions.getActiveDimensionsForRule(modelValue) > 0
            ? 'space-y-8'
            : 'space-y-4'
        "
      >
        <div class="space-y-2">
          <h3 class="text-spreedly-gray-700 text-xl lg:text-1xl my-2 mt-0">
            {{ $t("routing.rule.conditions") }}
          </h3>
          <div
            v-for="(
              dim_name, dim_index
            ) in dimensions.getActiveDimensionsForRule(modelValue)"
            :key="'dim_text-' + dim_index"
            class="space-y-1"
          >
            <div class="flex space-x-2 items-start">
              <div class="w-20">
                <div class="h-10 flex items-center">
                  <label
                    :class="{
                      'pt-12':
                        dim_name === 'metadata' || dim_name === 'bin_ranges',
                      'p-error': v$[dim_name]?.$errors.length > 0,
                    }"
                    :for="dim_name"
                    class="grow text-right text-sm font-bold"
                  >
                    {{ dimensions.getDim(dim_name).text }}
                  </label>
                </div>
              </div>
              <div class="grow w-[calc(100%-7.5rem)]">
                <div v-if="dim_name === 'amount'">
                  <div class="p-inputgroup flex-1">
                    <SelectButton
                      v-model="modelValue.amount.usd_amount_operator"
                      :options="
                        dimensions.getDim('amount.usd_amount_operator').options
                      "
                      optionDisabled="constant"
                      optionLabel="text"
                      optionValue="value"
                    />
                    <InputNumber
                      v-model="formattedUsdAmount"
                      inputId="currency-us"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                    />
                  </div>
                  <SpreedlyErrorMessage
                    v-if="v$.amount?.$errors"
                    :id="'ruleBeingEdited-dim-error'"
                    :errors="v$.amount.$errors"
                    class="mt-1"
                  />
                </div>
                <div v-else-if="dim_name === 'bin_ranges'">
                  <BinRanges
                    v-model="modelValue.bin_ranges"
                    @emptied="deactivateDimension('bin_ranges')"
                  />
                </div>
                <div v-else-if="dim_name === 'metadata'">
                  <MetadataEditor
                    v-model="modelValue.metadata"
                    @emptied="deactivateDimension('metadata')"
                  >
                  </MetadataEditor>
                </div>
                <div v-else>
                  <MultiSelect
                    v-model="modelValue[dim_name]"
                    :options="dimensions.getDim(dim_name).options"
                    :input-id="dim_name"
                    display="chip"
                    option-label="text"
                    option-value="value"
                  >
                    <template #headercheckboxicon="">
                      <label class="header-checkbox-label">{{
                        $t("selectAll")
                      }}</label>
                    </template>
                  </MultiSelect>
                  <SpreedlyErrorMessage
                    v-if="v$[dim_name]?.$errors"
                    :id="'ruleBeingEdited-dim-error'"
                    :errors="v$[dim_name].$errors"
                    class="mt-1"
                  />
                </div>
              </div>
              <SpreedlyIconButton
                v-if="!dimHasOwnControls(dim_name)"
                name="minus"
                size="xs"
                @click="deactivateDimension(dim_name)"
                inverse
                class="h-6 w-6 mt-2"
                :aria-label="$t('remove') + '-' + dim_name"
                :title="$t('remove')"
              />
            </div>
            <div
              class="pt-2 text-sm text-center"
              v-if="
                dim_index <
                dimensions.getActiveDimensionsForRule(modelValue).length - 1
              "
            >
              &mdash; {{ $t("and") }} &mdash;
            </div>
          </div>
        </div>
        <div
          v-if="dimensions.getInactiveDimensionsForRule(modelValue).length > 0"
        >
          <div
            class="mb-2"
            v-if="
              dimensions.getActiveDimensionsForRule(modelValue).length === 0
            "
          >
            <SpreedlyErrorMessage
              v-if="v$.activeDims.$errors"
              :id="'ruleBeingEdited-conditions-error'"
              :errors="v$.activeDims.$errors"
            />
            {{ $t("routing.rule.select") }}:
          </div>
          <div class="pt-2">
            <SpreedlyButton
              v-for="(
                dim_name, dim_index
              ) in dimensions.getInactiveDimensionsForRule(modelValue)"
              :key="'dim_activate-' + dim_index"
              inverse
              @click="activateDimension(dim_name)"
              :text="dimensions.getDim(dim_name).text"
              size="sm"
              class="mr-2 mb-2"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { deepCopy } from "@/services/HelperService";
import { useOptionsStore } from "@/stores/routing-rules/OptionsStore";
import i18n from "@/i18n";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Message from "primevue/message";
import SelectButton from "primevue/selectbutton";
import Retry from "@/components/RoutingRetry.vue";
import GatewaySplit from "@/components/RoutingTransactionRulesGatewaySplit.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyErrorMessage from "@/components/SpreedlyErrorMessage.vue";
import SpreedlyIconButton from "@/components/SpreedlyIconButton.vue";
import dimensions from "@/services/routing-rules/RoutingRuleDimensionService";
import MultiSelect from "primevue/multiselect";
import BinRanges from "@/components/RoutingTransactionRulesBinRanges.vue";
import MetadataEditor from "@/components/RoutingTransactionRulesMetadataEditor.vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, maxLength, required } from "@vuelidate/validators";
import type { Gateway } from "@/models/routing-rules/gateway.model";
import type { Rule } from "@/models/routing-rules/rule.model";
import type { RuleTarget } from "@/models/routing-rules/rule-target.model";

dimensions.setData();

const props = defineProps<{ modelValue: Rule }>();

const optionsStore = useOptionsStore();

// validation
const state = computed(() => ({
  name: props.modelValue.name,
  amount: props.modelValue.amount?.usd_amount,
  activeDims: dimensions.getActiveDimensionsForRule(props.modelValue),
  ...dimensionsWithValues(),
}));

const activeDimensionRequired = (dim_name: string) => (value?: []) =>
  !dimensions.getActiveDimensionsForRule(props.modelValue).includes(dim_name) ||
  (!!value && value.length > 0);
const greaterThanZero = (value: number) =>
  !dimensions.getActiveDimensionsForRule(props.modelValue).includes("amount") ||
  value > 0;

const rules = {
  name: {
    nameIsRequired: required,
    maxLength: helpers.withMessage(
      i18n.global.t("validations.ruleNameMaxLength"),
      maxLength(50)
    ),
    $autoDirty: true,
  },
  amount: {
    minValue: helpers.withMessage(
      i18n.global.t("validations.minimumAmount"),
      greaterThanZero
    ),
  },
  activeDims: {
    dimIsRequired: helpers.withMessage(
      i18n.global.t("validations.requiredCondition"),
      required
    ),
  },
  ...dimensionRules(),
};

const v$ = useVuelidate(rules, state);

const activateDimension = (dim) => {
  setDimension(dim, deepCopy(dimensions.getDim(dim).default));
};

const deactivateDimension = (dim) => {
  setDimension(dim, null);
};

const setDimension = (dim, val) => {
  props.modelValue[dim] = val;
};

const dimHasOwnControls = (dimName: string) => {
  return dimName === "bin_ranges" || dimName === "metadata";
};

// Build out a list of simple array dimensions with their values for state
function dimensionsWithValues() {
  return dimensions
    .getSimpleDimensions()
    .reduce((o, key) => ({ ...o, [key]: props.modelValue[key] }), {});
}

// Build out a list of rules for simple array dimensions, validating that any active field has a value before save
function dimensionRules() {
  return dimensions.getSimpleDimensions().reduce(
    (o, key) => ({
      ...o,
      [key]: {
        isRequired: helpers.withMessage(
          i18n.global.t("validations.emptyNotAllowed"),
          activeDimensionRequired(key)
        ),
      },
    }),
    {}
  );
}

const formattedUsdAmount = computed({
  get: () => props.modelValue.amount.usd_amount / 100,
  set: (val) => {
    props.modelValue.amount.usd_amount = Math.round(val * 100);
  },
});

const displaySplitRetryMismatch = computed(() => {
  // check if there are multiple targets and if the retry mode is set
  if (props.modelValue.targets?.length > 1 && props.modelValue.retry?.mode) {
    // we don't need to display the warning if the secondary gateway key has not been set or if the retry mode is outage only
    if (
      props.modelValue.targets[1].gateway_key === null ||
      props.modelValue.retry?.mode === "outage_only"
    ) {
      return false;
    }
    // otherwise get a list of the gateway types from the targets
    const gatewayTypes = props.modelValue.targets.map((target: RuleTarget) => {
      return optionsStore.options?.gateways.find(
        (gateway: Gateway) => gateway.key === target.gateway_key
      )?.type;
    });
    // then check how many of those types are supported for retry
    const supported = gatewayTypes.filter((type: string) =>
      optionsStore?.options?.retryable_gateways?.includes(type)
    ).length;
    return supported > 0 && supported < gatewayTypes.length;
  }
  return false;
});
</script>
