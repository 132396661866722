<template>
  <div>
    <SpreedlyPageTitle :title="`${$t('org.reports')}`" tabs>
      <template v-slot:nav-tabs>
        <div>
          <button
            v-if="store.hasPermission('dashboard.read')"
            id="organization-overview-report-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportOrganizationOverview',
            }"
            @click="router.push({ name: 'ReportOrganizationOverview' })"
          >
            {{ sentenceCase($t("overview")) }}
          </button>
          <button
            v-if="store.hasPermission('dashboard.read')"
            id="organization-success-rates-report-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportOrganizationSuccessRates',
            }"
            @click="router.push({ name: 'ReportOrganizationSuccessRates' })"
          >
            {{ sentenceCase($t("successRates")) }}
          </button>
          <button
            v-if="store.hasPermission('dashboard.read')"
            id="organization-failures-report-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportOrganizationFailures',
            }"
            @click="router.push({ name: 'ReportOrganizationFailures' })"
          >
            {{ sentenceCase($t("failures")) }}
          </button>
          <button
            v-if="store.hasPermission('dashboard.read')"
            id="organization-transactions-report-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportOrganizationTransactions',
            }"
            @click="router.push({ name: 'ReportOrganizationTransactions' })"
          >
            {{ sentenceCase($t("transactions.transactions")) }}
          </button>
          <button
            v-if="store.hasPermission('dashboard.read')"
            id="organization-3ds2-report-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportOrganization3DS2',
            }"
            @click="router.push({ name: 'ReportOrganization3DS2' })"
          >
            {{ $t("3ds2") }}
          </button>
          <button
            v-if="store.hasPermission('dashboard.read')"
            id="organization-payment-methods-report-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportOrganizationPaymentMethods',
            }"
            @click="router.push({ name: 'ReportOrganizationPaymentMethods' })"
          >
            {{ sentenceCase($t("payment_method", 2)) }}
          </button>
          <button
            v-if="
              store.hasPermission('dashboard.read') &&
              currentOrganization.allow_account_updater
            "
            id="organization-routing-rules-retry-report-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportOrganizationAccountUpdater',
            }"
            @click="router.push({ name: 'ReportOrganizationAccountUpdater' })"
          >
            {{ $t("lifecycleManagement") }}
          </button>
          <button
            v-if="
              store.hasPermission('dashboard.read') &&
              store.hasAccessToRoutingRules
            "
            id="organization-merchant-aggregator-report-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportOrganizationRoutingRules',
            }"
            @click="router.push({ name: 'ReportOrganizationRoutingRules' })"
          >
            {{ $t("recover") }}
          </button>
          <button
            v-if="store.hasPermission('dashboard.read')"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportOrganizationMerchantAggregator',
            }"
            @click="
              router.push({ name: 'ReportOrganizationMerchantAggregator' })
            "
          >
            {{ sentenceCase($t("merchantAggregator")) }}
          </button>
        </div>
      </template>
    </SpreedlyPageTitle>
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { useSettingsStore } from "@/stores/SettingsStore";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import { sentenceCase } from "@/services/HelperService";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

const store = useSettingsStore();
const { currentOrganization } = storeToRefs(store);

const router = useRouter();
</script>
