<template>
  <div>
    <SpreedlyPageTitle
      :title="$t('env.overview', { environment: currentEnvironment.name })"
    ></SpreedlyPageTitle>
    <div class="mt-4 md:mt-0 mb-8">
      <div
        :class="width < 1100 ? 'xl:flex-col' : 'xl:flex-row'"
        class="flex flex-col justify-between w-full"
      >
        <SpreedlyMarketingCard
          :title="$t('advancedVault.advancedVault')"
          :subtitle="$t('advancedVault.configure')"
          :content="$t('advancedVault.marketing')"
          class="mr-8"
        >
          <template v-slot:tag>
            <Tag
              :value="
                currentOrganization.payment_method_management_enabled
                  ? $t('enabled')
                  : $t('disabled')
              "
              severity="info"
              rounded
              class="!text-spreedly-gray-700 inline-flex rounded-[10rem] text-base font-bold py-[0.3rem] px-4 my-4 mr-2 bg-spreedly-gray-300/[.8]"
              unstyled
            ></Tag>
          </template>
          <template v-slot:graphic>
            <img class="absolute -left-6" :src="advancedVaultGraphic" />
          </template>
          <template v-slot:button>
            <router-link
              v-if="store.hasPermission('environment.read')"
              :to="{ name: 'AdvancedVault' }"
              class="bg-white w-full p-4 border border-white rounded-br-2xl text-right text-spreedly-blue-600 overflow-hidden whitespace-nowrap text-ellipsis mt-6 ml-[25%]"
            >
              {{ $t("advancedVault.view") }}
              <i class="pi pi-arrow-right ml-2"></i>
            </router-link>
            <a
              v-else
              :href="`${docsUrl}/docs/advanced-vault`"
              target="_blank"
              class="bg-white w-full p-4 border border-white rounded-br-2xl text-right text-spreedly-blue-600 overflow-hidden whitespace-nowrap text-ellipsis"
            >
              {{ $t("documentation.go") }}
              <i class="pi pi-external-link ml-2"></i>
            </a>
          </template>
        </SpreedlyMarketingCard>
        <SpreedlyMarketingCard
          :title="$t('gateway_.connections')"
          :subtitle="$t('gateway_.orchestrate')"
          :content="$t('gateway_.marketing')"
          class="mr-8"
        >
          <template v-slot:tag>
            <Tag
              :value="
                $t('gateway_.count', currentEnvironment.gateways?.length || 0)
              "
              severity="info"
              rounded
              class="!text-spreedly-gray-700 inline-flex rounded-[10rem] text-base font-bold py-[0.3rem] px-4 my-4 mr-2 bg-spreedly-teal/[.3]"
              unstyled
            ></Tag>
          </template>
          <template v-slot:graphic>
            <img class="absolute -left-4" :src="gatewaysGraphic" />
          </template>
          <template v-slot:button>
            <router-link
              v-if="store.hasPermission('gateway.read')"
              :to="{ name: 'GatewayList' }"
              class="bg-white w-full p-4 border border-white rounded-br-2xl text-right text-spreedly-blue-600 overflow-hidden whitespace-nowrap text-ellipsis mt-6 ml-[25%]"
            >
              {{ $t("gateway_.viewYourConnections") }}
              <i class="pi pi-arrow-right ml-2"></i>
            </router-link>
            <a
              v-else
              :href="`${docsUrl}/docs/create-gateways-and-receivers`"
              target="_blank"
              class="bg-white w-full p-4 border border-white rounded-br-2xl text-right text-spreedly-blue-600 overflow-hidden whitespace-nowrap text-ellipsis"
            >
              {{ $t("documentation.go") }}
              <i class="pi pi-external-link ml-2"></i>
            </a>
          </template>
        </SpreedlyMarketingCard>
        <SpreedlyMarketingCard
          :title="$t('documentation.documentation')"
          :subtitle="$t('documentation.getStarted')"
          :content="$t('documentation.marketing')"
        >
          <template v-slot:tag>
            <Tag
              :value="$t('documentation.guides')"
              severity="info"
              rounded
              class="!text-spreedly-blue-700 inline-flex rounded-[10rem] text-base font-bold py-[0.3rem] px-4 my-4 mr-2 bg-[#E1F5FF]"
              unstyled
            ></Tag>
            <Tag
              :value="$t('documentation.features')"
              severity="info"
              rounded
              class="!text-spreedly-blue-700 inline-flex rounded-[10rem] text-base font-bold py-[0.3rem] px-4 my-4 mr-2 bg-[#E1F5FF]"
              unstyled
            ></Tag>
          </template>
          <template v-slot:graphic>
            <img class="absolute -left-16" :src="documentationGraphic" />
          </template>
          <template v-slot:button>
            <a
              :href="docsUrl"
              target="_blank"
              class="bg-white w-full p-4 border border-white rounded-br-2xl text-right text-spreedly-blue-600 overflow-hidden whitespace-nowrap text-ellipsis mt-6 ml-[25%]"
            >
              {{ $t("documentation.go") }}
              <i class="pi pi-external-link ml-2"></i>
            </a>
          </template>
        </SpreedlyMarketingCard>
      </div>
    </div>
    <SpreedlyLookerEmbed
      v-if="store.hasPermission('dashboard.read')"
      :dashboard-id="574"
      id="looker-env-summary"
    />
  </div>
</template>

<script setup lang="ts">
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyMarketingCard from "@/components/SpreedlyMarketingCard.vue";
import Tag from "primevue/tag";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import { useBreakpoints } from "@/composables/useBreakpoints";
import advancedVaultGraphic from "@/assets/logos/advanced-vault-graphic.svg";
import gatewaysGraphic from "@/assets/logos/gateways-graphic.svg";
import documentationGraphic from "@/assets/logos/documentation-graphic.svg";
import SpreedlyLookerEmbed from "@/components/SpreedlyLookerEmbed.vue";

const { width } = useBreakpoints();
const store = useSettingsStore();

const { currentOrganization, currentEnvironment } = storeToRefs(store);
const docsUrl = import.meta.env.VITE_DOCS_URL;
</script>
