<template>
  <slot>
    <div id="branded-background" class="text-spreedly-gray-500 mt-20">
      <div class="flex flex-col relative top-2/3 w-full text-center py-40">
        <h1 class="text-6xl lg:text-8xl -mt-30 font-bold">500</h1>
        <h2 class="text-2xl lg:text-4xl font-bold">
          {{ $t("internalError.header") }}
        </h2>
        <i18n-t
          scope="global"
          :keypath="'internalError.subheader'"
          tag="h3"
          class="text-lg lg:text-xl"
        >
          <template v-slot:contactUs>
            <a
              class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
              href="mailto:success@spreedly.com"
              >{{ $t("internalError.contactUs") }}</a
            >
          </template>
        </i18n-t>
      </div>
    </div>
  </slot>
</template>

<script setup lang="ts"></script>
<style lang="css" scoped>
#branded-background {
  background-image: url("@/assets/logos/flock-of-spreedly.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
}
</style>
