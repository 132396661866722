import http from "@/http";
import type { OptionsResponse } from "@/services/routing-rules/responses/OptionsResponse";
import type { DefaultGatewayResponse } from "@/services/routing-rules/responses/DefaultGatewayResponse";
import type { RulesResponse } from "@/services/routing-rules/responses/RulesResponse";
import type { Options } from "@/models/routing-rules/options.model";

function api() {
  const url = import.meta.env.VITE_ROUTING_RULES_API_SERVER_URL,
    options = url ? { baseURL: url } : {};
  return http(options);
}

const path = (envKey: string, orgKey: string, path = "") => {
  return `/organizations/${orgKey}/environments/${envKey}/routing_rules${path}`;
};

const buildTargetIfEmpty = (rule) => {
  // if there is no targets on the rule (legacy), create a target object and put it on the rule
  if (rule.targets === undefined && rule.gateway_key !== null) {
    rule.targets = [{ gateway_key: rule.gateway_key, volume: 100 }];
    delete rule.gateway_key;
  }
  return rule;
};

const retryFlatten = (rule) => {
  // if the retry object is empty, because it was modified for presentation in the Retry component, we need to null it out
  rule.retry_mode = rule.retry?.mode ?? null;
  rule.retry_gateway_tokens =
    rule.retry?.gateway_tokens.length > 0 ? rule.retry.gateway_tokens : null;
  delete rule.retry;
  return rule;
};

const retryUnflatten = (rule) => {
  rule.retry = {
    mode: rule.retry_mode || null,
    gateway_tokens: rule.retry_gateway_tokens || [],
  };
  delete rule.retry_mode;
  delete rule.retry_gateway_tokens;
  return rule;
};

const retryToNullIfEmpty = (sourceRetry) => {
  return sourceRetry?.mode ? sourceRetry : null;
};

const retryToEmptyIfNull = (sourceRetry) => {
  return sourceRetry || { gateway_tokens: [], mode: null };
};

function isOptions(dataOptions: any): dataOptions is Options {
  return dataOptions.card_brands !== undefined;
}

export async function getOptions(
  envKey: string,
  orgKey: string
): Promise<OptionsResponse> {
  return await api()
    .get(path(envKey, orgKey, "/options"))
    .then((res) => {
      return {
        data: isOptions(res.data.options) ? res.data.options : undefined,
        status: "ok",
        message: undefined,
      };
    })
    .catch((err) => {
      return {
        data: undefined,
        status: "error",
        message: err.message,
      };
    });
}

export async function getDefaultGateway(
  envKey: string,
  orgKey: string
): Promise<DefaultGatewayResponse> {
  return await api()
    .get(path(envKey, orgKey, "/default_gateway"))
    .then((res) => {
      res.data.default_gateway.retry = retryToEmptyIfNull(
        res.data.default_gateway.retry
      );
      return {
        data: res.data.default_gateway,
        status: "ok",
        message: "",
      };
    })
    .catch((err) => {
      return {
        data: null,
        status: "error",
        message: err.message,
      };
    });
}

export async function putDefaultGateway(
  envKey: string,
  orgKey: string,
  data: any
): Promise<DefaultGatewayResponse> {
  data.retry = retryToNullIfEmpty(data.retry);
  return await api()
    .put(path(envKey, orgKey, "/default_gateway"), data)
    .then((res) => {
      res.data.default_gateway.retry = retryToEmptyIfNull(
        res.data.default_gateway.retry
      );
      return {
        data: res.data.default_gateway,
        status: "ok",
        message: "",
      };
    })
    .catch((err) => {
      return {
        data: null,
        status: "error",
        message: err.message,
      };
    });
}

export async function getRules(
  envKey: string,
  orgKey: string
): Promise<RulesResponse> {
  return await api()
    .get(path(envKey, orgKey))
    .then((res) => {
      const staticRules =
        res.data.static_rules?.map((rule) => {
          rule = buildTargetIfEmpty(rule);
          return retryUnflatten(rule);
        }) ?? [];
      return {
        data: staticRules,
        status: "ok",
        message: "",
      };
    })
    .catch((err) => {
      return {
        data: null,
        status: "error",
        message: err.message,
      };
    });
}

export async function putRules(
  envKey: string,
  orgKey: string,
  data: any
): Promise<RulesResponse> {
  data = {
    rules: data.map((rule) => {
      return rule.retry ? retryFlatten(rule) : rule;
    }),
  };
  return await api()
    .put(path(envKey, orgKey), data)
    .then((res) => {
      const staticRules =
        res.data.static_rules?.map((rule) => {
          return retryUnflatten(rule);
        }) ?? [];
      return {
        data: staticRules,
        status: "ok",
        message: "",
      };
    })
    .catch((err) => {
      return {
        data: null,
        status: "error",
        message: err.message,
      };
    });
}
