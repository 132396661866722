import { ref } from "vue";
import { defineStore } from "pinia";
import {
  RetryOptions,
  type Options,
} from "@/models/routing-rules/options.model";
import { getOptions } from "@/services/routing-rules/RoutingRulesService";
import type { OptionsResponse } from "@/services/routing-rules/responses/OptionsResponse";

export const useOptionsStore = defineStore("routing-rules-options", () => {
  const options = ref<Options>();
  const status = ref<string>("uninitialized");
  const message = ref<string>();

  function load(envKey: string, orgKey: string) {
    status.value = "loading";
    getOptions(envKey, orgKey).then((res: OptionsResponse) => {
      if (res.data) {
        options.value = {
          ...res.data,
          retry_options: RetryOptions,
        };
        status.value = res.status;
        message.value = res.message ?? undefined;
      }
    });
  }

  return { load, options, status, message };
});
