<template>
  <div class="pt-8 md:mx-8 mx-4 2xl:w-[1400px] 2xl:mx-[calc((100%-1400px)/2)]">
    <div class="flex flex-row justify-end">
      <div
        class="lg:col-start-6 md:col-span-2 lg:col-span-1 -mt-1 lg:mt-1.5 inline-block"
      >
        <router-link :to="{ name: 'EnvironmentOverview' }">
          <img
            class="h-8"
            src="@/assets/logos/full-blue.svg"
            alt="Spreedly Blue Logo"
          />
        </router-link>
      </div>
    </div>
  </div>
  <div class="flex flex-col relative top-1/3 w-full text-center justify-center">
    <h1 class="text-3xl lg:text-4xl text-spreedly-gray-600 mt-20 mb-5">
      {{ $t("passwordReset.header") }}
    </h1>
    <h2 class="text-lg lg:text-xl text-spreedly-gray-500">
      {{ $t("passwordReset.message") }}
    </h2>
  </div>
  <div class="flex flex-row justify-center py-20">
    <img
      src="@/assets/logos/flock-of-spreedly.svg"
      alt="flock-of-spreedly-logo"
      width="600"
    />
  </div>
</template>
<script lang="ts" setup></script>
