<template>
  <div class="flex flex-row justify-end" id="search-component">
    <button
      class="inline-flex justify-start cursor-pointer items-center p-2 mt-1 text-spreedly-gray-500 hover:text-spreedly-blue-600 h-1/2"
      @click="openSearch()"
      :title="$t('search')"
      v-if="breakpoint === 'sm' || breakpoint === 'md'"
    >
      <SpreedlyIcon name="magnify"></SpreedlyIcon>
    </button>
    <div
      v-show="breakpoint !== 'md' && breakpoint !== 'sm'"
      v-click-outside="closeSearch"
      class="relative"
      :class="{ 'w-full': expandSearch, 'w-[135px]': !expandSearch }"
    >
      <div
        tabindex="0"
        role="button"
        @keyup.enter="openSearch()"
        class="relative flex flex-row justify-between rounded-md text-spreedly-gray-500 cursor-text"
        :class="{ 'w-full': expandSearch }"
      >
        <input
          v-model.trim="searchStr"
          id="search-input"
          ref="searchInput"
          :readonly="!expandSearch"
          @keyup.esc="closeSearch(false)"
          maxlength="50"
          @click="openSearch()"
          @keyup.enter="search()"
          :placeholder="expandSearch ? $t('searchPlaceholder') : $t('search')"
          class="w-full pl-2 focus:outline-none border-y border-l rounded-l-md"
        />
        <button
          v-if="expandSearch"
          id="close-search-button"
          class="bg-white px-2 border-y mr-0.5"
          @click="closeSearch"
        >
          <i class="pi pi-times"></i>
        </button>
        <button
          @click="expandSearch ? search() : openSearch()"
          id="search-button"
          :title="$t('search')"
          class="bg-white rounded-r-md px-2 py-1 border-y border-r cursor-text"
          :class="{
            'text-spreedly-blue-600 hover:text-spreedly-blue-700 !cursor-pointer':
              expandSearch,
            'focus:outline-none': !expandSearch,
          }"
        >
          <SpreedlyIcon name="magnify"></SpreedlyIcon>
        </button>
      </div>
      <div
        :class="{
          '-right-0.5': width < 1720,
          'right-13 ': breakpoint === '2xl',
        }"
        class="bg-white mt-4 shadow rounded px-4 z-50 absolute w-[578px]"
      >
        <SearchTabView
          v-if="expandSearch"
          v-model:search-category="searchCategory"
          v-model:search-str="searchStr"
          v-model:search-result="result"
          v-model:result-not-found="resultNotFound"
          v-model:recent-search="recentSearch"
          @search="search()"
          @go="go"
        />
      </div>
    </div>
    <Dialog
      v-model:visible="searchDialog"
      header="Header"
      close-on-escape
      class="p-dialog-maximized"
      :pt="{
        closeButtonIcon: { class: 'hidden' },
      }"
      :style="{ width: '50rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <template #header>
        <div
          role="button"
          tabindex="0"
          class="relative flex flex-row justify-between text-base text-spreedly-gray-500 w-full mr-4"
        >
          <input
            v-model.trim="searchStr"
            id="dialog-search-input"
            ref="dialogSearchInput"
            autofocus
            maxlength="50"
            @keyup.enter="search()"
            :placeholder="$t('searchPlaceholder')"
            class="w-full focus:outline-none p-2 border-y border-l border-spreedly-gray-300 rounded-y-md rounded-l-md"
          />
          <button
            class="bg-white border-y border-r border-spreedly-gray-300 rounded-r-md px-2 py-2 text-spreedly-blue-600 hover:text-spreedly-blue-700"
            @click="search()"
          >
            <SpreedlyIcon name="magnify"></SpreedlyIcon>
          </button>
        </div>
      </template>
      <SearchTabView
        v-model:search-category="searchCategory"
        v-model:search-str="searchStr"
        v-model:search-result="result"
        v-model:result-not-found="resultNotFound"
        v-model:recent-search="recentSearch"
        @search="search()"
        @go="go"
      />
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";
import Dialog from "primevue/dialog";
import { defineModel, nextTick, ref, watch } from "vue";
import type { Breakpoint } from "@/composables/useBreakpoints";

import { useBreakpoints } from "@/composables/useBreakpoints";
import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";
import type { RecentSearch, SearchResult } from "@/services/SearchService";
import {
  GATEWAY_TOKEN,
  searchAllEnvironmentsByGatewayToken,
  searchAllEnvironmentsByTxnToken,
  TRANSACTION_TOKEN,
} from "@/services/SearchService";
import router from "@/router";
import SearchTabView from "@/components/SearchTabView.vue";

const store = useSettingsStore();
const { currentOrganization, currentEnvironment, user } = storeToRefs(store);
const { breakpoint, width } = useBreakpoints();
const expandSearch = defineModel<boolean>("expandSearch", { required: true });
const searchStr = ref("");
const result = ref<SearchResult>();
const resultNotFound = ref<boolean>(false);
const searchInput = ref<HTMLInputElement | null>(null);
const searchDialog = ref(false);
const searching = ref(false);

const searchCategory = ref<typeof TRANSACTION_TOKEN | typeof GATEWAY_TOKEN>(
  TRANSACTION_TOKEN
); // used in search logic as it won't change when language is changed

watch(
  // resets resultNotFound when searchStr value changes
  () => searchStr.value,
  () => {
    if (resultNotFound.value && searchStr.value.length > 0) {
      resultNotFound.value = false;
    }
  }
);

watch(
  // switches from dialog search to inline search if search is open and screen size changes
  () => breakpoint.value,
  (newValue: Breakpoint, oldValue: Breakpoint) => {
    if (
      smallToBigScreen(newValue, oldValue) ||
      bigToSmallScreen(newValue, oldValue)
    ) {
      openSearch(true);
    }
  }
);

watch(
  //resets focus on input and clears result when search category is changed
  () => searchCategory.value,
  () => {
    if (result.value) {
      result.value = undefined;
    }
    resultNotFound.value = false;
    focusInput();

    if (searchStr.value) {
      search();
    }
  }
);

function smallToBigScreen(current: Breakpoint, oldVal: Breakpoint): boolean {
  return (
    searchDialog.value &&
    current !== "sm" &&
    current !== "md" &&
    (oldVal === "sm" || oldVal === "md")
  );
}

function bigToSmallScreen(current: Breakpoint, oldVal: Breakpoint): boolean {
  return (
    expandSearch.value &&
    (current === "sm" || current === "md") &&
    oldVal !== "sm" &&
    oldVal !== "md"
  );
}

const focusInput = () => {
  nextTick(() => {
    if (searchInput.value) {
      searchInput.value.focus();
    }
  });
};

function openSearch(retainSearch?: boolean) {
  parseRecentSearch();
  if (breakpoint.value === "sm" || breakpoint.value === "md") {
    searchDialog.value = true;
    closeSearch(retainSearch);
  } else {
    searchDialog.value = false;
    expandSearch.value = true;
    focusInput();
  }
}

const closeSearch = (retainSearch?: boolean) => {
  expandSearch.value = false;
  if (!retainSearch) {
    searchStr.value = "";
  }
};

const recentSearch = ref<RecentSearch>({
  transactionToken: [],
  gatewayToken: [],
});

function parseRecentSearch() {
  const recentSearchStr = sessionStorage.getItem(
    `recent-search-${user.value.key}-${currentOrganization.value.key}`
  );
  if (recentSearchStr) {
    const searchObj = JSON.parse(recentSearchStr);
    if (
      // make sure the object is formatted correctly
      Object.hasOwn(searchObj, TRANSACTION_TOKEN) &&
      Object.hasOwn(searchObj, GATEWAY_TOKEN)
    ) {
      recentSearch.value = searchObj;
    }
  }
}

const go = async (search?: SearchResult) => {
  const searchResult = search || result.value;

  if (searchResult?.token) {
    await navigateToNewRoute(searchResult.token);
    addSearchToRecentSearches(searchResult);
    await nextTick(() => {
      searchDialog.value = false;
      closeSearch();
    });
  }
};

async function navigateToNewRoute(token: string) {
  switch (searchCategory.value) {
    case TRANSACTION_TOKEN:
      await router.push({
        name: "TransactionDetailSummary",
        params: {
          id: token,
        },
      });
      break;
    case GATEWAY_TOKEN:
      await router.push({
        name: "GatewayDetailSummary",
        params: {
          id: token,
        },
      });
      break;
    //TODO: add other routes to navigate to
    default:
      break;
  }
}

async function search() {
  searching.value = true;
  let searchFn;
  switch (searchCategory.value) {
    case GATEWAY_TOKEN:
      searchFn = searchAllEnvironmentsByGatewayToken.bind(
        null,
        currentOrganization.value.key,
        currentEnvironment.value.key,
        searchStr.value.trim(),
        store.environments,
        store.user
      );
      break;
    case TRANSACTION_TOKEN:
    default:
      //current default = transaction token search but later can be expanded to search all potentially
      searchFn = searchAllEnvironmentsByTxnToken.bind(
        null,
        currentOrganization.value.key,
        currentEnvironment.value.key,
        searchStr.value.trim(),
        store.environments,
        store.user
      );
      break;
  }

  result.value = await searchFn?.call(null);

  if (!result.value) {
    resultNotFound.value = true;
    searchInput.value?.select();
    searchInput.value?.focus();
  }
  searching.value = false;
}

function addSearchToRecentSearches(search: SearchResult) {
  if (Object.hasOwn(recentSearch.value, searchCategory.value)) {
    const index = recentSearch.value[searchCategory.value].findIndex(
      (x: SearchResult) => x.token === search.token
    );

    if (index !== -1) {
      // searchStr is already in recentSearch array so remove it
      recentSearch.value[searchCategory.value].splice(index, 1);
    }

    recentSearch.value[searchCategory.value].unshift(search);
    recentSearch.value[searchCategory.value] =
      recentSearch.value[searchCategory.value].slice(-10); // keep most recent 10 searches
  }

  sessionStorage.setItem(
    `recent-search-${user.value.key}-${currentOrganization.value.key}`,
    JSON.stringify(recentSearch.value)
  );
}
</script>
