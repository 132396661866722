import type {
  Gateway,
  GatewaySelectOptions,
} from "@/models/routing-rules/gateway.model";

export function findGateway(gateways: Gateway[], key: string): Gateway {
  return gateways.find((gateway) => gateway.key === key) ?? { key };
}

export function gatewayToString(gateway: Gateway): string {
  let text = `${gateway.type}: ${gateway.key}`;
  if (gateway.description) {
    text += `: ${gateway.description}`;
  }
  return text;
}

export function selectOptions(gateways?: Gateway[]): GatewaySelectOptions[] {
  if (gateways) {
    return gateways.map((gateway) => ({
      value: gateway.key,
      label: gatewayToString(gateway),
    }));
  } else {
    return [];
  }
}
