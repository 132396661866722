<template>
  <div
    class="space-y-3 border-l-4 pl-2"
    v-if="modelValue.mode && modelValue.gateway_tokens"
  >
    <div class="text-sm">
      <p
        v-html="
          modelValue.mode === 'outage_only'
            ? $t('routing.retry.outageDescription')
            : $t('routing.retry.genericDescription')
        "
      ></p>
    </div>
    <ol class="list-decimal ml-4 space-y-2">
      <li v-for="gatewayKey in modelValue.gateway_tokens" :key="gatewayKey">
        <GatewayDescription :gateway="findGateway(gatewayList, gatewayKey)" />
      </li>
    </ol>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import GatewayDescription from "@/components/RoutingGatewayDescription.vue";
import { findGateway } from "@/services/routing-rules/gateways";
import { useOptionsStore } from "@/stores/routing-rules/OptionsStore";
import type { Gateway } from "@/models/routing-rules/gateway.model";

defineProps<{
  modelValue: any;
}>();

const { options } = storeToRefs(useOptionsStore());
const gatewayList: Gateway[] = options.value?.gateways ?? [];
</script>
