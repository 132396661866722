<template>
  <div>
    <h2 class="text-spreedly-gray-700 text-xl lg:text-2xl my-2 mt-0">
      {{ $t("routing.defaultGateway.defaultGateway") }}
    </h2>
    <div v-if="status === 'loading' || status === 'saving'">
      <SpreedlyLoadingSpinner />
    </div>
    <div v-else>
      <Message v-if="status === 'error'" severity="error">
        {{ $t("errorMessage.somethingWentWrong") }}
      </Message>
      <div v-if="defaultGateway" :key="defaultGateway.key">
        <GatewayDescription
          v-if="defaultGateway.key"
          :gateway="findGateway(gatewaysList, defaultGateway.key)"
        />
        <p v-else>
          {{ $t("routing.defaultGateway.noneSelected") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDefaultGatewayStore } from "@/stores/routing-rules/DefaultGatewayStore";
import GatewayDescription from "@/components/RoutingGatewayDescription.vue";
import { findGateway } from "@/services/routing-rules/gateways";
import { useOptionsStore } from "@/stores/routing-rules/OptionsStore";
import type { Gateway } from "@/models/routing-rules/gateway.model";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";
import Message from "primevue/message";
import { storeToRefs } from "pinia";
const { defaultGateway, status } = storeToRefs(useDefaultGatewayStore());

const gatewaysList: Gateway[] = useOptionsStore().options?.gateways ?? [];
</script>
