<template>
  <SpreedlyKeyValueList
    v-if="txn"
    :obj="txn"
    :show-keys="transactionKeys()"
  ></SpreedlyKeyValueList>
</template>

<script lang="ts" setup>
import SpreedlyKeyValueList from "@/components/SpreedlyKeyValueList.vue";
import { TRANSACTION_TYPES_WITH_AMOUNTS } from "@/services/TransactionService";
import type { Transaction } from "@/services/TransactionService";

const keys = [
  "token",
  "transaction_type",
  "status",
  "time",
  "succeeded",
  "message",
];

const props = defineProps<{
  txn: Transaction;
}>();

function transactionKeys() {
  if (TRANSACTION_TYPES_WITH_AMOUNTS.includes(props.txn.transaction_type)) {
    keys.push("amount");
  }

  if (props.txn.payment_method) {
    keys.push("payment_method");
  }

  if (props.txn.gateway) {
    keys.push("gateway");
    keys.push("gateway_token");
  }

  return keys;
}
</script>
