<template>
  <div>
    <SpreedlyPageTitle :title="`${$t('accessSecret.organization')}`">
      <template v-slot:info>
        <i18n-t
          scope="global"
          :keypath="'accessSecret.infoMessage.text'"
          tag="p"
          class="text-caption"
        >
          <template v-slot:link>
            <a
              :href="`${docsUrl}/docs/your-account#access-secrets`"
              target="_blank"
              class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
              >{{ $t("accessSecret.infoMessage.link") }}
            </a>
          </template>
        </i18n-t>
      </template>
    </SpreedlyPageTitle>
    <AccessSecretList />
  </div>
</template>

<script setup lang="ts">
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import AccessSecretList from "@/components/AccessSecretList.vue";

const docsUrl = import.meta.env.VITE_DOCS_URL;
</script>
