<template>
  <div class="flex h-screen bg-spreedly-gray-50 font-sans">
    <TheSidebar />
    <div
      class="flex-1 flex flex-col overflow-hidden 2xl:justify-center"
      v-if="currentOrganization.key"
    >
      <main
        class="mx-2 px-2 md:mx-4 md:px-4 2xl:w-[1400px] 2xl:mx-[calc((100%-1400px)/2)] flex-1 overflow-x-hidden overflow-y-auto scrollbar-hide bg-background-color"
      >
        <router-view />
      </main>
    </div>
    <ToastEnvironmentSwitched
      :key="currentEnvironment.key"
      @undo="undoEnvironmentChange"
    />
  </div>
</template>

<script setup async lang="ts">
import TheSidebar from "@/components/TheSidebar.vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";
import { useAuth0 } from "@auth0/auth0-vue";
import { datadogRum } from "@datadog/browser-rum";
import ToastEnvironmentSwitched from "@/components/ToastEnvironmentSwitched.vue";
import type { Environment } from "@/services/EnvironmentService";

const store = useSettingsStore();
const { user } = useAuth0();
const { currentOrganization, currentEnvironment } = storeToRefs(store);
await store.fillOrganizations();
const undoEnvironmentChange = async (previousEnv: Environment) => {
  await store.changeCurrentEnvironment(previousEnv);
};

if (import.meta.env.VITE_PENDO_API_KEY) {
  /* global pendo */
  pendo.initialize({
    excludeAllText: true,
    cookieDomain: ".spreedly.com",
    visitor: {
      id: user.value?.sub?.split("|").at(-1),
      email: user.value?.email,
    },

    account: {
      id: currentOrganization.value.key,
      name: currentOrganization.value.name,
      active: currentOrganization.value.active,
      ever_active: currentOrganization.value.ever_active,
      account_updater_enabled:
        currentOrganization.value.account_updater_enabled,
    },
  });
}

if (
  import.meta.env.VITE_DATADOG_APPLICATION_ID &&
  import.meta.env.VITE_DATADOG_CLIENT_TOKEN
) {
  datadogRum.setUser({
    email: user.value?.email,
    key: user.value?.sub?.split("|").at(-1),
    organization_name: currentOrganization.value.name,
    organization_key: currentOrganization.value.key,
  });
}
</script>
