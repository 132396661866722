<template>
  <div>
    <SpreedlyPageTitle :title="$t('marketplace')">
      <template v-slot:info>
        <div
          class="flex flex-col md:flex-col lg:flex-row lg:justify-between pb-4 text-spreedly-gray-600 -mt-2"
        >
          <i18n-t
            scope="global"
            :keypath="'gateway_.marketplaceDescription'"
            tag="p"
            class="text-caption"
          >
          </i18n-t>
        </div>
      </template>
    </SpreedlyPageTitle>

    <div class="flex flex-row justify-end pb-4 -mt-4">
      <Button
        class="text-spreedly-blue-600 hover:text-spreedly-blue-700 text-nowrap"
        id="marketplace-view-your-connections-button"
        icon="pi pi-arrow-right"
        icon-pos="right"
        text
        :label="$t('gateway_.viewYourConnections')"
        @click="router.push({ name: 'GatewayList' })"
      ></Button>
    </div>
    <MarketplacePremierConnections
      v-model:connections="premierGatewayTypes"
    ></MarketplacePremierConnections>
    <div class="mt-8">
      <MarketplaceStandardConnections
        v-model:connections="standardGatewayTypes"
      ></MarketplaceStandardConnections>
    </div>
  </div>
</template>

<script setup lang="ts">
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import MarketplacePremierConnections from "@/components/MarketplacePremierConnections.vue";
import MarketplaceStandardConnections from "@/components/MarketplaceStandardConnections.vue";

import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import Button from "primevue/button";
import router from "@/router";
const store = useSettingsStore();
const { gatewayOptions } = storeToRefs(store);

const premierGatewayTypes = computed(() => {
  return gatewayOptions.value
    .filter((gateway) => gateway.partnership_tier === "premier")
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((x, y) => (x.connected === y.connected ? 0 : x.connected ? -1 : 1));
});

const standardGatewayTypes = computed(() => {
  return gatewayOptions.value
    .filter((gateway) => gateway.partnership_tier === "standard")
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((x, y) => (x.connected === y.connected ? 0 : x.connected ? -1 : 1));
});
</script>
