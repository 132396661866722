<template>
  <div>
    <SpreedlyPageTitle :title="$t('activityLog.activityLog')">
    </SpreedlyPageTitle>

    <DataTable
      paginator
      :rows="10"
      striped-rows
      :rowsPerPageOptions="[5, 10, 20, 50]"
      :size="'small'"
      removable-sort
      column-resize-mode="fit"
      sortField="time"
      :sort-order="-1"
      :value="activityLogs"
      :pt="{
        root: { class: 'font-sans border rounded-md' },
        headerRow: { class: '!text-spreedly-gray-400 border-b' },
        bodyRow: { class: 'last:border-b-0 border-b' },
        wrapper: { class: 'p-4' },
        paginator: {
          root: {
            class: 'font-sans ',
          },
          rowPerPageDropdown: {
            root: {
              class:
                'flex !ml-4 !mb-0 border border-spreedly-gray-400 ml-4 items-center !w-[5.2rem]',
            },
          },
        },
      }"
    >
      <template #empty>
        <span class="text-spreedly-gray-500 italic" v-if="!loadingLogs">{{
          $t("activityLog.noLogs")
        }}</span>
      </template>
      <template #header>
        <div class="text-left mt-2 ml-2" v-if="activityLogs?.length">
          <SpreedlyButton
            :icon="{ position: 'left', name: 'download' }"
            :inverse="false"
            :text="$t('downloadCsv')"
            @click="downloadCsvData()"
          ></SpreedlyButton>
        </div>
      </template>
      <Column
        v-for="col in headers"
        :field="col.field"
        :header="$t(col.field)"
        :sortable="col.sortable"
        :pt="{
          sort: { class: 'text-spreedly-blue-600' },
          sortIcon: { class: 'text-spreedly-blue-600' },
          headerTitle: { class: '!text-spreedly-gray-600' },
          bodyCell: {
            class: '!text-spreedly-gray-600',
          },
        }"
      >
        <template #body="slotProps">
          <Skeleton v-if="loadingLogs" width="60%" height="1rem" />
          <div v-else>
            <div v-if="col.type === 'DateString'">
              {{ formatDate(slotProps.data[col.field]) }}
            </div>
            <div v-else>
              {{ slotProps.data[col.field] }}
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script setup lang="ts">
import exportFromJSON from "export-from-json";
import { storeToRefs } from "pinia";
import { watch, ref, onMounted } from "vue";
import { fetchActivityLogs } from "@/services/OrganizationService";
import { useSettingsStore } from "@/stores/SettingsStore";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import { formatDate } from "@/services/HelperService";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Skeleton from "primevue/skeleton";

const store = useSettingsStore();
const { currentOrganization } = storeToRefs(store);
const activityLogs = ref(Array.from({ length: 10 }).map((_) => generateLog()));
const loadingLogs = ref(false);

watch(
  () => currentOrganization.value.key,
  () => {
    loadActivityLogs();
  }
);
onMounted(() => {
  loadActivityLogs();
});

function generateLog() {
  return {
    time: "",
    event: "",
    performedBy: "",
    target: "",
    previousState: "",
    newState: "",
  };
}
const headers = [
  {
    field: "time",
    type: "DateString",
    sortable: false,
  },
  {
    field: "event",
    type: "String",
    sortable: false,
  },
  {
    field: "performedBy",
    type: "String",
    sortable: false,
  },
  {
    field: "target",
    type: "String",
    sortable: false,
  },
  {
    field: "previousState",
    type: "String",
    sortable: false,
  },
  {
    field: "newState",
    type: "String",
    sortable: false,
  },
];

async function loadActivityLogs() {
  loadingLogs.value = true;
  try {
    activityLogs.value = await fetchActivityLogs(currentOrganization.value.key);
  } catch (err) {
    activityLogs.value = [];
  } finally {
    loadingLogs.value = false;
  }
}

function downloadCsvData() {
  const todayDate = new Date().toISOString().split("T")[0];
  const data = activityLogs.value;
  const fileName = `spreedly-activity-logs-${todayDate}`;
  const exportType = exportFromJSON.types.csv;

  if (data) exportFromJSON({ data, fileName, exportType });
}
</script>
