<template>
  <div>
    <SpreedlyLookerEmbed
      :dashboard-id="308"
      id="looker-embed-routing-rules"
      :org-view="props.orgView"
    />
    <SpreedlyLookerEmbed
      :dashboard-id="277"
      id="looker-embed-transaction-rules"
      :org-view="props.orgView"
    />
  </div>
</template>
<script setup lang="ts">
import SpreedlyLookerEmbed from "@/components/SpreedlyLookerEmbed.vue";
const props = defineProps<{
  orgView?: boolean;
}>();
</script>
