<template>
  <div>
    <SpreedlyPageTitle :title="$t('routingRules')"></SpreedlyPageTitle>
    <div v-if="optionsStore.status === 'ok'" class="w-129">
      <Message
        v-if="!hasSeenInstructions"
        class="border rounded-lg drop-shadow-sm -mt-2 mb-10 !bg-spreedly-blue-100"
        severity="info"
        :closable="false"
        icon="false"
      >
        <div class="text-xl">{{ $t("routing.instructions.title") }}</div>
        <ul class="list-disc ml-4 my-2">
          <li>{{ $t("routing.instructions.step_one") }}</li>
          <li>{{ $t("routing.instructions.step_two") }}</li>
          <li>{{ $t("routing.instructions.step_three") }}</li>
        </ul>
        <div>
          <SpreedlyTextButton
            @click="hasSeenInstructions = true"
            :text="$t('dismiss')"
          />
        </div>
      </Message>

      <SpreedlyContainer>
        <DefaultGateway />
      </SpreedlyContainer>
      <SpreedlyContainer>
        <Rules />
      </SpreedlyContainer>
    </div>
    <div v-else-if="optionsStore.status === 'error'">
      <Message severity="error" :closable="false">
        {{ $t("loadError") }}
      </Message>
    </div>
    <div v-else class="text-center mt-12">
      <SpreedlyLoadingSpinner />
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, ref } from "vue";
import type { Ref } from "vue";
import { storeToRefs } from "pinia";
import { useOptionsStore } from "@/stores/routing-rules/OptionsStore";
import { useDefaultGatewayStore } from "@/stores/routing-rules/DefaultGatewayStore";
import Rules from "@/components/RoutingRulesSettings.vue";
import DefaultGateway from "@/components/RoutingDefaultGatewaySettings.vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import Message from "primevue/message";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";
import SpreedlyTextButton from "@/components/SpreedlyTextButton.vue";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";

type HasSeenInstructions = boolean;

const store = useSettingsStore();
const { currentEnvironment, currentOrganization } = storeToRefs(store);

const optionsStore = useOptionsStore();
const defaultGatewayStore = useDefaultGatewayStore();

const hasSeenInstructions: Ref<HasSeenInstructions> = ref(
  localStorage.getItem("rr-has-seen-instructions") === "true"
);

const load = () => {
  optionsStore.load(
    currentEnvironment.value.key as string,
    currentOrganization.value.key
  );
  defaultGatewayStore.load(
    currentEnvironment.value.key as string,
    currentOrganization.value.key
  );
};

watch(hasSeenInstructions, (val: HasSeenInstructions): void =>
  localStorage.setItem("rr-has-seen-instructions", val.toString())
);

watch(currentEnvironment, load);

load();
</script>
