<template>
  <div class="space-y-4">
    <div class="field">
      <span
        :class="{ 'p-error': v$.mode.$errors.length > 0 }"
        for="dd-retry-mode"
        >{{ $t("routing.retry.mode") }}
      </span>
      <div>
        <Dropdown
          v-model="modelValue.mode"
          :options="options?.retry_options"
          input-id="dd-retry-mode"
          option-label="text"
          option-value="value"
          :placeholder="$t('none')"
        />
        <SpreedlyErrorMessage
          v-if="v$.mode.$errors"
          class="-mt-3"
          :errors="v$.mode.$errors"
        />
      </div>
    </div>

    <div
      v-for="(_, gatewayTokenIndex) in modelValue.gateway_tokens"
      :key="'mode_option-' + gatewayTokenIndex"
      :class="gatewayTokenIndex > 0 ? 'additional-gateway' : ''"
    >
      <span
        :class="{ 'p-error': filterErrors(gatewayTokenIndex).length > 0 }"
        for="dd-retry-gateway"
      >
        {{ $t("ordinals." + (gatewayTokenIndex + 1)) }}
        {{ $t("routing.retry.gateway") }}
      </span>
      <div class="flex space-x-2">
        <Dropdown
          v-model="modelValue.gateway_tokens[gatewayTokenIndex]"
          :disabled="!modelValue.mode"
          :label="`$($t('ordinals.' + (gatewayTokenIndex + 1))) Retry Gateway`"
          :options="selectOptions(options?.gateways)"
          input-id="dd-retry-gateway"
          option-label="label"
          option-value="value"
          text=""
          :placeholder="$t('routing.retry.selectGateway')"
          @change="gatewaysChange"
        />
        <SpreedlyIconButton
          name="minus"
          size="xs"
          v-if="gatewayTokenIndex > 0"
          @click="removeGateway(gatewayTokenIndex)"
          :disabled="!modelValue.mode"
          class="ml-2 h-6 w-6 mt-2"
          inverse
          :title="$t('remove')"
        />
      </div>
      <SpreedlyErrorMessage
        v-if="filterErrors(gatewayTokenIndex)"
        class="-mt-3"
        :errors="filterErrors(gatewayTokenIndex)"
      />
    </div>
  </div>
  <SpreedlyButton
    v-if="modelValue.mode && modelValue.gateway_tokens?.length < 2"
    class="my-2"
    :disabled="addGatewayDisabled"
    :text="$t('routing.retry.addGateway')"
    :icon="{ position: 'left', name: 'plus' }"
    inverse
    size="xs"
    @click="addGateway"
  ></SpreedlyButton>
</template>

<script setup lang="ts">
import { watch, computed } from "vue";
import type { RuleTarget } from "@/models/routing-rules/rule-target.model";
import { deepCopy } from "@/services/HelperService";
import SpreedlyErrorMessage from "./SpreedlyErrorMessage.vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import i18n from "@/i18n";
import {
  validateRetryMode,
  standardRetryModeMessage,
} from "@/services/routing-rules/RoutingRetryValidationService";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyIconButton from "@/components/SpreedlyIconButton.vue";
import { useOptionsStore } from "@/stores/routing-rules/OptionsStore";
import Dropdown from "primevue/dropdown";
import { findGateway, selectOptions } from "@/services/routing-rules/gateways";

const props = defineProps<{
  modelValue: any;
  targetData: RuleTarget[] | string | undefined;
}>();

const { options } = useOptionsStore();
const retryMode = computed(() => props.modelValue.mode);

//updates gateway tokens array if mode is changed to or from "None"
watch(retryMode, (newMode, oldMode) => {
  // there's terser ways to do it, but no one would understand it
  if (newMode !== oldMode && (newMode === null || oldMode === null)) {
    props.modelValue.gateway_tokens =
      newMode === null ? [] : [selectOptions(options?.gateways)[0].value];
  }
});

const gatewaysChange = () => {
  props.modelValue.gateway_tokens = deepCopy(props.modelValue.gateway_tokens);
};
const addGateway = () => {
  props.modelValue.gateway_tokens.push(
    selectOptions(options?.gateways)[0].value
  );
};
const removeGateway = (index: number) => {
  props.modelValue.gateway_tokens.splice(index);
};

// validation //
// retry mode supported on gateway? //
function validateRetryModeFromData(
  mode: string,
  targetData: string | RuleTarget[]
) {
  let gatewayTypes = [];
  if (targetData instanceof Array) {
    gatewayTypes = buildGatewayTypesArray(targetData);
  } else {
    gatewayTypes = [getGatewayTypeFromKey(targetData)];
  }
  return validateRetryMode(mode, gatewayTypes);
}
function getGatewayTypeFromKey(key: string) {
  return key ? findGateway(options?.gateways, key).type : null;
}
function buildGatewayTypesArray(targets: RuleTarget[]): string[] {
  const gatewayTypes: string[] = [];
  targets.forEach((target) => {
    gatewayTypes.push(getGatewayTypeFromKey(target.gateway_key));
  });
  return gatewayTypes;
}
function modeValidator() {
  return validateRetryModeFromData(props.modelValue.mode, props.targetData);
}
// additional gateways disallowed with outage_only mode//
function additionalGatewaysUnsupportedWithOutageOnlyMode() {
  return props.modelValue.mode === "outage_only"
    ? props.modelValue.gateway_tokens[1] === undefined
    : true;
}
const additionalGatewayUnsupportedValidator = helpers.withParams(
  { errorFilter: 1 },
  additionalGatewaysUnsupportedWithOutageOnlyMode
);
// v$ //
const state = {
  mode: retryMode,
  gateway_tokens: props.modelValue.gateway_tokens,
};
const rules = {
  mode: {
    mode: helpers.withMessage(standardRetryModeMessage(), modeValidator),
    $autoDirty: true,
  },
  gateway_tokens: {
    outageOnlyModeDisallowed: helpers.withMessage(
      i18n.global.t("validations.additionalGatewaysUnsupported"),
      additionalGatewayUnsupportedValidator
    ),
    $autoDirty: true,
  },
};
const v$ = useVuelidate(rules, state);

const filterErrors = (errorFilter: number) => {
  return v$.value.gateway_tokens.$errors.filter(
    (e) => e.$params.errorFilter === errorFilter
  );
};

const addGatewayDisabled = computed(() => {
  v$.value.mode.$touch();
  return (
    !props.modelValue.mode ||
    props.modelValue.mode === "outage_only" ||
    v$.value.mode.$errors.length > 0
  );
});
</script>
