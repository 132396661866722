<template>
  <SpreedlyContainer>
    <div>
      <div class="text-sm text-left">
        <dl
          v-for="item in list"
          :key="item.key"
          class="border-b border-gray-300 last:border-none"
        >
          <div class="grid grid-cols-2 gap-4 lg:py-4 py-2">
            <dt
              :title="$t(item.key)"
              class="text-sm font-semibold text-spreedly-gray-600 lg:px-8"
            >
              {{ $t(item.key) }}
            </dt>
            <dd
              class="text-sm text-spreedly-gray-600 px-2 lg:-px-8 overflow-hidden whitespace-nowrap text-ellipsis"
            >
              <Chip
                v-if="isChipColumn(item.value)"
                class="px-4 text-sm"
                :label="item.value.displayValue"
                :class="item.value.class"
              ></Chip>
              <router-link
                v-else-if="isButtonColumn(item.key)"
                :id="item.key"
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                :to="{
                  name: 'GatewayDetailSummary',
                  params: { id: item.value },
                }"
              >
                {{ item.value }}
              </router-link>
              <span v-else>
                {{ formatValue(item.key, item.value) }}
              </span>
            </dd>
          </div>
        </dl>
      </div>
      <div class="px-8">
        <slot name="action"></slot>
      </div>
    </div>
  </SpreedlyContainer>
</template>

<script setup lang="ts">
import { computed } from "vue";
import i18n from "@/i18n/index";
import Chip from "primevue/chip";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";

const props = defineProps<{
  obj: Record<string, string | number | boolean | null | undefined | unknown>;
  showKeys?: string[];
}>();

const list = computed(() => generateKeyValueList(props.obj));

function generateKeyValueList(obj: Record<string, any> | undefined) {
  const arr = [];
  if (obj) {
    // display only properties passed in from showKeys object
    if (props.showKeys && props.showKeys.length > 0) {
      for (const property in obj) {
        if (props.showKeys.includes(property)) {
          arr.push({ key: property, value: obj[property] });
        }
      }
    } else {
      // default to showing all properties on the object
      for (const property in obj) {
        arr.push({
          key: property,
          value: obj[property],
        });
      }
    }
  }
  return arr;
}

function formatValue(
  key: string,
  value: string | number | boolean | null | undefined
) {
  if (value === undefined || value === null) {
    return i18n.global.t("none");
  } else {
    return value;
  }
}

function isChipColumn(value: unknown) {
  if (value && typeof value === "object") {
    return (
      Object.hasOwn(value as object, "displayValue") &&
      Object.hasOwn(value as object, "class")
    );
  }
  return false;
}

function isButtonColumn(key: string) {
  const buttonColumns = ["gateway_token"];

  return key && buttonColumns.includes(key);
}
</script>
