<template>
  <div class="space-y-1">
    <div class="flex items-start">
      <div class="grow">
        <h2 class="text-spreedly-gray-700 text-xl lg:text-2xl my-2 mt-0">
          {{ $t("routing.rule.transactionRules") }}
        </h2>
        <div></div>
      </div>
      <div v-if="canEdit" class="space-x-4 flex justify-end">
        <SpreedlyButton
          size="sm"
          @click="add"
          inverse
          :text="$t('routing.rule.add')"
          :disabled="!editable || rulesStore.rules === undefined"
        />
        <SpreedlyButton
          :text="$t('reset')"
          :disabled="!saveable"
          @click="reset"
          size="sm"
          inverse
        />
        <SpreedlyButton
          :text="$t('routing.rule.save')"
          size="sm"
          :disabled="!saveable"
          @click="save"
        />
      </div>
    </div>
    <div
      v-if="
        status === 'loading' ||
        status === 'saving' ||
        defaultGatewayStatus === 'loading'
      "
      class="text-center"
    >
      <SpreedlyLoadingSpinner />
    </div>
    <div v-else>
      <Message v-if="!defaultGateway" severity="error">
        {{ $t("errorMessage.generic") }}
      </Message>
      <Message
        v-else-if="options?.gateways?.length === 0 || !defaultGateway.key"
        severity="warn"
        :closable="false"
      >
        {{ $t("routing.rule.noDefaultGateway") }}
      </Message>
      <div v-else>
        <Message v-if="status === 'error'" severity="error">
          {{ $t("errorMessage.somethingWentWrong") }}
        </Message>
        <Message
          v-else-if="saveWasOk"
          severity="success"
          @close="saveWasOk = null"
        >
          {{ $t("successMessage.generic") }}
        </Message>
        <Message
          v-else-if="editable && editedRules.length === 0"
          severity="warn"
          :closable="false"
        >
          {{ $t("routing.rule.noRules") }}
        </Message>
        <div>
          <draggable v-model="editedRules" item-key="ui_key">
            <template #item="{ element }">
              <div
                class="border bg-white mb-4 w-[calc(100%-2rem)] p-4 rounded-lg drop-shadow-sm relative"
                :class="{
                  'bg-spreedly-gray-100': element.toBeDeleted,
                }"
              >
                <div v-if="element.toBeDeleted">
                  <span class="text-spreedly-red">
                    <span class="font-bold">{{ element.name }}</span>
                    {{ $t("routing.rule.deleteMessage") }} </span
                  ><br />
                  <SpreedlyTextButton
                    @click="delete element.toBeDeleted"
                    :text="$t('routing.rule.restore')"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="store.hasPermission('routing_rules.update')"
                    class="text-right absolute top-4 right-4 z-10"
                  >
                    <SpreedlyIconButton
                      name="pencil"
                      size="xs"
                      basic
                      @click="ruleBeingEdited = deepCopy(element)"
                      :title="$t('edit')"
                      :class="'text-spreedly-gray-600'"
                    />
                    <SpreedlyIconButton
                      name="delete"
                      size="xs"
                      basic
                      @click="element.toBeDeleted = true"
                      :title="$t('delete')"
                      :class="'text-spreedly-gray-600'"
                    />
                  </div>
                  <TransactionRule
                    :rule="element"
                    :showDetails="!element.toBeDeleted"
                  />
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </div>
    </div>
    <ConfirmDialog></ConfirmDialog>

    <Dialog
      v-model:visible="ruleBeingEdited"
      :draggable="false"
      :class="{ 'p-dialog-maximized': width < 480 }"
      :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
      :style="{ width: '50vw' }"
      close-on-escape
      modal
    >
      <template #header>
        <h1 class="text-xl font-medium leading-normal text-spreedly-gray-700">
          {{
            $t(
              `${
                ruleBeingEdited.name
                  ? "routing.rule.edit"
                  : "routing.rule.create"
              }`
            )
          }}
        </h1>
      </template>
      <TransactionRuleEditor v-model="ruleBeingEdited" />
      <template #footer>
        <SpreedlyButton
          :text="$t('cancel')"
          @click="ruleBeingEdited = null"
          inverse
        >
        </SpreedlyButton>
        <SpreedlyButton :text="$t('done')" @click="commitRuleEdits">
        </SpreedlyButton>
      </template>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
import { deepCopy, isEqual } from "@/services/HelperService";
import draggable from "vuedraggable";
import i18n from "@/i18n";
import { storeToRefs } from "pinia";
import TransactionRule from "@/components/RoutingTransactionRule.vue";
import TransactionRuleEditor from "@/components/RoutingTransactionRuleEditor.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyIconButton from "@/components/SpreedlyIconButton.vue";
import SpreedlyTextButton from "@/components/SpreedlyTextButton.vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import { useRulesStore } from "@/stores/routing-rules/RulesStore";
import { useOptionsStore } from "@/stores/routing-rules/OptionsStore";
import { useDefaultGatewayStore } from "@/stores/routing-rules/DefaultGatewayStore";
import { useBreakpoints } from "@/composables/useBreakpoints";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import Dialog from "primevue/dialog";
import Message from "primevue/message";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";
import useVuelidate from "@vuelidate/core";

const { width } = useBreakpoints();
const confirm = useConfirm();
const store = useSettingsStore();
const { currentEnvironment, currentOrganization } = storeToRefs(store);
const rulesStore = useRulesStore();
const { rules, status, editedRules } = storeToRefs(rulesStore);
const { options } = storeToRefs(useOptionsStore());
const { defaultGateway, status: defaultGatewayStatus } = storeToRefs(
  useDefaultGatewayStore()
);
const hasSeenRemindToSaveDialog = ref(false);
const canEdit = store.hasPermission("routing_rules.update");
const ruleBeingEdited = ref();
const saveWasOk = ref();

const save = () => {
  const rulesToSave = deepCopy(editedRules.value).filter(
    (rule: any) => !rule.toBeDeleted
  );
  rulesStore.save(
    currentEnvironment.value.key as string,
    currentOrganization.value.key,
    rulesToSave.filter((rule: any) => !rule.toBeDeleted)
  );
};

const editable = computed(() => {
  return Array.isArray(editedRules.value) && options.value.gateways?.length > 0;
});

const saveable = computed(() => {
  if (!editedRules.value || !rules.value) {
    return false;
  }
  const filterForComp = (rule: unknown) => {
    rule = deepCopy(rule);
    delete rule["ui_key"];
    return rule;
  };
  return !isEqual(
    editedRules.value.map((rule: any) => filterForComp(rule)),
    rules.value.map((rule: any) => filterForComp(rule))
  );
});

const load = () =>
  rulesStore.load(
    currentEnvironment.value.key as string,
    currentOrganization.value.key
  );
load();

watch(saveable, (newValue: boolean) => {
  if (newValue && !hasSeenRemindToSaveDialog.value) {
    hasSeenRemindToSaveDialog.value = true;
    confirm.require({
      message: i18n.global.t("routing.rule.savePrompt"),
      header: i18n.global.t("routing.rule.saveReminder"),
      acceptLabel: i18n.global.t("ok"),
      rejectClass: "invisible",
    });
  }
});

watch(status, (val, oldVal) => {
  if (val === "ok") {
    if (oldVal === "saving") {
      saveWasOk.value = true;
    }
  }
});

const reset = () => {
  confirm.require({
    message: i18n.global.t("routing.rule.resetMessage"),
    header: i18n.global.t("routing.rule.resetChanges"),
    acceptLabel: i18n.global.t("reset"),
    rejectLabel: i18n.global.t("cancel"),
    acceptClass: "border-spreedly-red bg-spreedly-red text-white",
    accept: () => {
      editedRules.value = deepCopy(rules.value);
    },
  });
};
//  TODO: need to type these routing rules. and update refs.
const add = () => {
  ruleBeingEdited.value = {
    name: i18n.global.t("routing.rule.new"),
    targets: [
      {
        gateway_key: null,
        volume: 100,
      },
    ],
    retry: {
      mode: null,
      gateway_tokens: [],
    },
  };
};

// validation
const v$ = useVuelidate();

const commitRuleEdits = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) {
    return;
  }
  const newEditedRules = deepCopy(editedRules.value);
  const newRule = deepCopy(ruleBeingEdited.value);
  if (!newRule.ui_key) {
    newRule.ui_key = uuidv4();
    newEditedRules.push(newRule);
  } else {
    const index = newEditedRules.findIndex(
      (rule) => rule.ui_key === newRule.ui_key
    );
    newEditedRules[index] = newRule;
  }
  editedRules.value = newEditedRules;
  ruleBeingEdited.value = null;
};
</script>
