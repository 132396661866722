<template>
  <SpreedlyPrettyJson
    v-if="txn && txn.raw_json"
    :raw-json="txn.raw_json"
  ></SpreedlyPrettyJson>
</template>

<script lang="ts" setup>
import SpreedlyPrettyJson from "@/components/SpreedlyPrettyJson.vue";
import type { Transaction } from "@/services/TransactionService";
import { onMounted } from "vue";
import router from "@/router";

const props = defineProps<{
  id: string;
  txn: Transaction;
}>();
onMounted(async () => {
  if (!props.txn.token) {
    await router.push({
      name: "TransactionDetailSummary",
      params: { id: props.id },
    });
  }
});
</script>
