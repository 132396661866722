import { computed, ref } from "vue";
import {
  getDefaults,
  type Workflow,
  type WorkflowConclusion,
} from "@/services/WorkflowService";
import { v4 as uuidv4 } from "uuid";
import { deepCopy } from "@/services/HelperService";
import {
  fetchComposerEnabledGatewayConnections,
  type GatewayConnection,
} from "@/services/GatewayService";

export type GatewayConclusion = {
  uuid?: string;
  result: {
    gateway_key: string;
    gateway_type: string;
    parent_company_id: string;
  };
  weight: number;
};

const gatewayConclusions = ref<GatewayConclusion[]>([]);
const loadingGateways = ref(false);
const gateways = ref<GatewayConnection[]>([]);

export async function setGateways(
  orgKey: string,
  envKey: string,
  sandbox: boolean
) {
  loadingGateways.value = true;
  try {
    gateways.value = (
      await fetchComposerEnabledGatewayConnections(orgKey, envKey)
    ).filter((c) => {
      if (sandbox) {
        return c.sandbox || c.gateway_type === "test";
      } else {
        return !c.sandbox && c.gateway_type !== "test";
      }
    });
  } finally {
    loadingGateways.value = false;
  }
}
export const updateGatewayConclusion = (
  conclusion: GatewayConclusion,
  index: number
) => {
  if (gatewayConclusions.value && gatewayConclusions.value[index]) {
    gatewayConclusions.value[index] = conclusion;
    updateWeight(conclusion, index);
  }
};
export const formatWorkflowConclusion = (): WorkflowConclusion[] => {
  return deepCopy(gatewayConclusions.value).map(
    (gateway: GatewayConclusion) => {
      delete gateway.uuid;
      return gateway;
    }
  );
};
export const addGatewaySplit = () => {
  gatewayConclusions.value.push({
    result: { gateway_key: "", gateway_type: "", parent_company_id: "" },
    weight: 0,
    uuid: uuidv4(),
  });
};
export const resetGatewayConclusion = () => {
  gatewayConclusions.value = [
    {
      result: { gateway_key: "", gateway_type: "", parent_company_id: "" },
      weight: 100,
      uuid: uuidv4(),
    },
  ];
};
export const removeSplit = (index: number) => {
  if (gatewayConclusions.value[index]) {
    gatewayConclusions.value.splice(index, 1);
    gatewayConclusions.value[0].weight = 100;
  }
};
export const setGatewayConclusions = (workflow: Workflow) => {
  gatewayConclusions.value = [];
  try {
    const defaults = getDefaults(workflow.steps) as GatewayConclusion[];
    defaults.map((d: GatewayConclusion) => (d.uuid = uuidv4()));
    gatewayConclusions.value = defaults;
  } catch (err) {
    gatewayConclusions.value = [];
  }
};
export const updateWeight = (conclusion: GatewayConclusion, index: number) => {
  if (gatewayConclusions.value.length === 2) {
    if (index === 0) {
      gatewayConclusions.value[1].weight = 100 - conclusion.weight;
    } else {
      gatewayConclusions.value[0].weight = 100 - conclusion.weight;
    }
  }
};

export const resetGateways = () => {
  gateways.value = [];
  gatewayConclusions.value = [];
};

const selectedGatewayTypes = computed(() =>
  gatewayConclusions.value
    .map((gateway) => gateway.result.gateway_type)
    .filter((gateway) => gateway !== "")
);
export function useGatewayConclusions() {
  return {
    gatewayConclusions,
    loadingGateways,
    gateways,
    selectedGatewayTypes,
    resetGateways,
    updateGatewayConclusion,
    removeSplit,
    updateWeight,
    resetGatewayConclusion,
    addGatewaySplit,
    formatWorkflowConclusion,
    setGatewayConclusions,
  };
}
