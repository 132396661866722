<template>
  <div>
    <div class="field">
      <span
        for="dd-gatewaysplit-gateway1"
        :class="{ 'p-error': v$.gateway1.$errors.length > 0 }"
      >
        {{ $t("routing.gateway.gateway1", { ord: isVolumeSplit ? " 1" : "" }) }}
      </span>
      <div>
        <Dropdown
          v-model="modelValue[0].gateway_key"
          :options="gatewayOptions"
          input-id="dd-gatewaysplit-gateway1"
          option-label="label"
          option-value="value"
          :placeholder="$t('routing.gateway.noGatewayChosen')"
        />
        <SpreedlyErrorMessage
          class="-mt-4 mb-2"
          :errors="v$.gateway1.$errors"
        />
      </div>
    </div>
    <SpreedlyButton
      v-if="!isVolumeSplit"
      @click="addSplitVolume"
      :text="$t('routing.gateway.splitVolume')"
      size="xs"
      inverse
    />
    <div v-else>
      <div class="flex space-x-2">
        <div class="grow">
          <span
            for="dd-gatewaysplit-gateway2"
            :class="{ 'p-error': v$.gateway2.$errors.length > 0 }"
          >
            {{ $t("routing.gateway.gateway2") }}
          </span>
          <div class="flex">
            <Dropdown
              v-model="modelValue[1].gateway_key"
              :options="gatewayOptions"
              input-id="dd-gatewaysplit-gateway2"
              option-label="label"
              option-value="value"
              :placeholder="$t('routing.gateway.noGatewayChosen')"
            />
            <div>
              <SpreedlyIconButton
                name="minus"
                size="xs"
                @click="removeSplitVolume()"
                class="ml-2 h-6 w-6 mt-2"
                inverse
                :aria-label="$t('routing.gateway.removeGateway', { ord: '2' })"
                :title="$t('remove')"
              />
            </div>
          </div>
          <SpreedlyErrorMessage
            class="-mt-4 mb-2"
            :errors="v$.gateway2.$errors"
          />
        </div>
      </div>
      <div class="space-y-1">
        <div class="flex text-sm">
          <div class="grow">{{ $t("gateway") }} 1</div>
          <div>{{ $t("gateway") }} 2</div>
        </div>
        <div class="flex items-center">
          <div class="font-bold w-12 text-right">{{ sliderVal }}%</div>
          <div class="grow px-3">
            <Slider v-model="sliderVal" />
          </div>
          <div class="font-bold w-12 text-left">{{ 100 - sliderVal }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import i18n from "@/i18n";
import SpreedlyErrorMessage from "./SpreedlyErrorMessage.vue";
import SpreedlyIconButton from "@/components/SpreedlyIconButton.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";

import Dropdown from "primevue/dropdown";
import Slider from "primevue/slider";

import { useOptionsStore } from "@/stores/routing-rules/OptionsStore";
import { selectOptions } from "@/services/routing-rules/gateways";

const gatewayOptions = selectOptions(useOptionsStore().options?.gateways);

const props = defineProps<{
  modelValue: any;
  ruleGatewayKey?: string;
}>();

const sliderVal = ref(props.modelValue[0].volume);

const addSplitVolume = () => {
  props.modelValue.push({ gateway_key: null, volume: 0 });
};

const removeSplitVolume = () => {
  props.modelValue.pop();
  props.modelValue[0].volume = 100;
};

const isVolumeSplit = computed(() => {
  return props.modelValue.length > 1;
});

watch(sliderVal, (newVal: number) => {
  props.modelValue[0].volume = newVal;
  props.modelValue[1].volume = 100 - newVal;
});

// validation
const required = () => {
  return props.modelValue[0].gateway_key;
};
const requiredIfSplit = () => {
  return !isVolumeSplit.value || props.modelValue[1].gateway_key;
};
const rules = {
  gateway1: {
    required: helpers.withMessage(
      i18n.global.t("validations.required"),
      required
    ),
  },
  gateway2: {
    requiredIfSplit: helpers.withMessage(
      i18n.global.t("validations.required"),
      requiredIfSplit
    ),
  },
};

const v$ = useVuelidate(rules, props.modelValue);
</script>
