<template>
  <div>
    <div class="text-spreedly-gray-600 mb-4 -mt-4">
      <i18n-t
        :keypath="'infoMessage.3ds2'"
        tag="p"
        class="text-caption"
        scope="global"
      >
        <template v-slot:link>
          <a
            :href="`${docsUrl}/docs/3ds-solutions`"
            target="_blank"
            class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
          >
            <span class="flex-1">{{ $t("clickHere") }}</span>
          </a>
        </template>
      </i18n-t>
    </div>

    <SpreedlyLookerEmbed
      :dashboard-id="10"
      id="looker-embed-3ds"
      :org-view="props.orgView"
    />
  </div>
</template>
<script setup lang="ts">
import SpreedlyLookerEmbed from "@/components/SpreedlyLookerEmbed.vue";

const props = defineProps<{
  orgView?: boolean;
}>();

const docsUrl = import.meta.env.VITE_DOCS_URL;
</script>
