<template>
  <div>
    <div class="space-y-3">
      <div class="flex">
        <h3 class="text-spreedly-gray-700 text-xl lg:text-1xl my-2 mt-0 grow">
          <span>
            {{ rule.name }}
          </span>
        </h3>
      </div>
      <div v-if="rule.targets">
        <GatewaySplitDescription :rule-targets="rule.targets" />
      </div>
      <div class="dimensions-grid show gap-2">
        <template
          v-for="(
            dim_name, dim_name_index
          ) in dimensions.getActiveDimensionsForRule(rule)"
          :key="'dim_text-' + dim_name_index"
        >
          <div class="dimensions-grid__name">
            <span v-if="dim_name_index === 0">
              {{ $t("if") }}
            </span>
            <span
              v-else-if="
                dim_name_index <
                  dimensions.getActiveDimensionsForRule(rule).length &&
                dim_name_index > 0
              "
            >
              {{ $t("and") }} </span
            >&nbsp; <b>{{ dimensions.getDim(dim_name).text }}</b
            >&nbsp;
            {{ $t("is") }}
          </div>
          <div class="dimensions-grid__value">
            <template v-if="dim_name === 'amount'">
              <Tag
                class="bg-spreedly-blue-100 text-black font-normal py-0 leading-0"
              >
                {{
                  dimensions.getOption(
                    "amount.usd_amount_operator",
                    rule.amount.usd_amount_operator
                  ).text
                }}
                {{ $t("usd") }}{{ (rule.amount.usd_amount / 100).toFixed(2) }}
              </Tag>
            </template>
            <template v-else-if="dim_name === 'bin_ranges'">
              <span
                v-for="(option_value, option_index) in rule[dim_name]"
                :key="'option_value-' + option_index"
              >
                <Tag
                  class="bg-spreedly-blue-100 text-black font-normal py-0 leading-0"
                >
                  <span>
                    {{ option_value.bin_lower }}
                  </span>
                  <span
                    v-if="option_value.bin_lower !== option_value.bin_upper"
                  >
                    -
                    <span>{{ option_value.bin_upper }}</span>
                  </span>
                </Tag>
                <span
                  v-if="option_index < rule[dim_name].length - 1"
                  class="logic px-1"
                  >{{ $t("or") }}</span
                >
              </span>
            </template>
            <template v-else-if="dim_name === 'metadata'">
              <span
                v-for="(keyvalueset, kvi) in rule.metadata"
                :key="'metadata-kv' + kvi"
                class="keyvalueset"
              >
                <span
                  v-for="(keyvalue, vi) in keyvalueset"
                  :key="'metadata-v' + vi"
                  class="values"
                >
                  <Tag
                    class="bg-spreedly-blue-100 text-black font-normal py-0 leading-0"
                  >
                    {{ keyvalue.key }}={{ keyvalue.value }}
                  </Tag>
                  <span class="px-2" v-if="vi < keyvalueset.length - 1">
                    {{ $t("or") }}
                  </span>
                </span>
                <span v-if="kvi < rule.metadata.length - 1" class="px-1">
                  {{ $t("and") }}
                </span>
              </span>
            </template>
            <template v-else>
              <span
                v-for="(option_value, option_index) in rule[dim_name]"
                :key="'option_value-' + option_index"
              >
                <Tag
                  class="bg-spreedly-blue-100 text-black font-normal py-0 leading-0"
                >
                  {{ dimensions.getOption(dim_name, option_value).text }}
                </Tag>
                <span
                  v-if="option_index < rule[dim_name].length - 1"
                  class="px-1"
                  >{{ $t("or") }}</span
                >
              </span>
            </template>
          </div>
        </template>
      </div>
      <div v-if="rule.retry">
        <RetryDescription v-model="rule.retry" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import RetryDescription from "@/components/RoutingRetryDescription.vue";
import GatewaySplitDescription from "@/components/RoutingGatewaySplitDescription.vue";

import dimensions from "@/services/routing-rules/RoutingRuleDimensionService";
import Tag from "primevue/tag";
import type { Rule } from "@/models/routing-rules/rule.model";

defineProps<{ rule: Rule }>();

dimensions.setData();
</script>

<style scoped lang="css">
.dimensions-grid {
  display: grid;
  font-size: 0.875rem;
  line-height: 24px;
}

.dimensions-grid:empty {
  display: none;
}

.dimensions-grid__name {
  white-space: nowrap;
  width: 180px;
}

.dimensions-grid .v-input {
  margin-top: 0px;
  padding-top: 0px;
}

@media screen and (min-width: 481px) {
  .dimensions-grid {
    column-gap: 0.5rem;
    row-gap: 8px;
  }

  .dimensions-grid.show {
    grid-template-columns: auto 1fr;
  }

  .dimensions-grid.edit {
    grid-template-columns: auto 1fr 20px;
  }

  .dimensions-grid__name {
    text-align: right;
  }
}
@media screen and (max-width: 480px) {
  .dimensions-grid {
    grid-template-columns: 1fr;
    row-gap: 0;
  }

  .dimensions-grid.show .dimensions-grid__value:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
</style>
