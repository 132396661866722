<template>
  <SpreedlyContainer>
    <Message
      class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
      severity="error"
      :closable="false"
      v-if="displayErrorMessage"
      >{{ displayErrorMessage }}
    </Message>
    <div
      v-if="environment && environment.key"
      class="grid grid-cols-2 lg:grid-cols-4 gap-x-8"
    >
      <div class="col-span-2">
        <div class="flex flex-row items-center mb-2">
          <div>
            <img
              class="h-16"
              src="@/assets/logos/partners/stripe.svg"
              alt="Stripe logo"
            />
          </div>
          <div class="ml-4">
            <Tag
              v-if="!isLoading || connected"
              class="text-sm"
              rounded
              :severity="connected ? 'success' : 'info'"
              :value="
                connected
                  ? $t('connected').toLowerCase()
                  : $t('notConnected').toLowerCase()
              "
            ></Tag>
          </div>
        </div>
        <div v-if="!connected && !isLoading">
          <i18n-t
            scope="global"
            :keypath="'stripePaymentIntents.infoMessage'"
            tag="span"
          ></i18n-t>
          &nbsp;
          <a
            :href="stripeConnectDocsUrl"
            target="_blank"
            id="stripe-learn-more-link"
            class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
            >{{ $t("learnMore") }}</a
          >
        </div>
      </div>
      <div class="col-span-2 lg:col-span-1">
        <div v-if="connected">
          <label
            for="stripe-connection-environment"
            class="font-bold text-spreedly-gray-600"
          >
            {{ $t("stripePaymentIntents.environment") }}
          </label>
          <div
            id="stripe-connection-environment"
            class="text-spreedly-gray-600"
          >
            {{
              connectStripeTestEnvironment
                ? $t("stripePaymentIntents.testEnvironment")
                : $t("stripePaymentIntents.productionEnvironment")
            }}
          </div>
        </div>
        <form
          @save.prevent="connectToStripe(connectStripeTestEnvironment)"
          v-else
        >
          <div v-if="!connected && isLoading">
            <Skeleton width="10rem" class="mt-2"></Skeleton>
            <Skeleton width="8rem" class="mt-2"></Skeleton>
          </div>

          <div v-if="!connected && !isLoading">
            <label
              for="stripe-connection-radio"
              class="font-bold text-spreedly-gray-600"
            >
              {{ $t("stripePaymentIntents.environment") }}
            </label>
            <div id="stripe-connection-radio" class="mt-2">
              <div class="field-radiobutton mt-2">
                <RadioButton
                  name="stripeTestEnvironment"
                  :value="true"
                  :disabled="
                    connected || !store.hasPermission('environment.update')
                  "
                  id="stripe-test-environment"
                  v-model="connectStripeTestEnvironment"
                />
                <label
                  class="form-check-label ml-4 inline-block text-spreedly-gray-600"
                  for="stripe-test-environment"
                >
                  {{ $t("stripePaymentIntents.testEnvironment") }}
                </label>
              </div>
            </div>
            <div class="field-radiobutton mt-2">
              <RadioButton
                name="stripeProductionEnvironment"
                :value="false"
                :disabled="
                  connected || !store.hasPermission('environment.update')
                "
                id="stripe-production-environment"
                v-model="connectStripeTestEnvironment"
              />
              <label
                class="form-check-label ml-4 inline-block text-spreedly-gray-600"
                for="stripe-production-environment"
              >
                {{ $t("stripePaymentIntents.productionEnvironment") }}
              </label>
            </div>
          </div>
        </form>
      </div>
      <div
        class="lg:col-span-1 xl:flex xl:items-center xl:justify-end mt-4 xl:mt-0"
      >
        <SpreedlyButton
          v-if="!connected"
          :disabled="isLoading || !store.hasPermission('environment.update')"
          :text="$t('connect')"
          id="stripe-connect-button"
          :inverse="true"
          :icon="{ position: 'right', name: 'open-in-new' }"
          @click="connectToStripe(connectStripeTestEnvironment)"
        ></SpreedlyButton>
        <SpreedlyButton
          v-else
          :disabled="!store.hasPermission('environment.update')"
          :text="isLoading ? $t('disconnecting') : $t('disconnect')"
          :inverse="true"
          :icon="
            isLoading
              ? { position: 'left', name: 'dots-circle', state: 'saving' }
              : { position: 'right', name: 'open-in-new' }
          "
          @click="disconnectFromStripe()"
        ></SpreedlyButton>
      </div>
    </div>
    <ConfirmDialog group="disconnect-stripe" id="deleteDialog">
      <template #container="{ message, rejectCallback }">
        <div class="bg-white rounded">
          <div class="bg-spreedly-red p-4 text-white text-lg">
            {{ message.header }}
          </div>
          <div
            class="text-spreedly-red flex flex-row align-items-center w-full gap-3 border-bottom-1 surface-border p-4"
          >
            <i class="text-5xl text-primary-500 pi pi-exclamation-triangle"></i>
            <div>
              {{ message.message }}
              <ul class="flex flex-col list-disc ml-4">
                <li v-for="gateway in stripeConnectGateways" :key="gateway">
                  <router-link
                    class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700"
                    :to="{
                      name: 'PartnerGatewayDetailSummary',
                      params: {
                        id: gateway,
                        gatewayType: 'stripe_payment_intents',
                      },
                    }"
                  >
                    {{ gateway }}
                  </router-link>
                </li>
              </ul>
              <div class="mt-6">
                {{ $t("confirmations.stripe.info") }}
              </div>
            </div>
          </div>

          <div class="flex flex-row justify-end p-4">
            <button
              autofocus
              @click="rejectCallback"
              class="border-4 text-spreedly-blue-700 border-spreedly-blue-700 p-1 rounded"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </template>
    </ConfirmDialog>
  </SpreedlyContainer>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useSettingsStore } from "@/stores/SettingsStore";
import {
  disconnectStripeEnvironment,
  fetchStripeOauthRequestPath,
  stripeConnectGatewaysList,
  updateStripeEnvironment,
} from "@/services/StripeService";
import RadioButton from "primevue/radiobutton";
import Message from "primevue/message";
import Skeleton from "primevue/skeleton";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import Tag from "primevue/tag";
import i18n from "@/i18n";
import type { Environment } from "@/services/EnvironmentService";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";

const store = useSettingsStore();
const route = useRoute();
const router = useRouter();
const isLoading = ref(false);
const connectStripeTestEnvironment = ref(true);
const displayErrorMessage = ref<string | null>(null);
const stripeConnectGateways = ref<string[]>([]);
const confirm = useConfirm();

const props = defineProps<{
  environment?: Environment;
}>();

onMounted(() => {
  checkStripeConnection();
});

const connected = computed(() => {
  return !!(
    props.environment &&
    (props.environment.test_stripe_user_id || props.environment.stripe_user_id)
  );
});
const stripeConnectDocsUrl = `${
  import.meta.env.VITE_DOCS_URL
}/docs/stripe-payment-intents-gateway-guide`;

async function connectToStripe(connectToTest: boolean) {
  displayErrorMessage.value = null;
  if (props.environment) {
    try {
      window.location.href = await fetchStripeOauthRequestPath(
        store.currentOrganization.key,
        props.environment.key as string,
        connectToTest
      );
    } catch (err) {
      displayErrorMessage.value = i18n.global.t(
        "errorMessage.actionNotPerformed"
      );
    }
  }
}

async function disconnectFromStripe() {
  if (stripeConnectGateways.value.length > 0) {
    confirmDisconnect();
  } else {
    displayErrorMessage.value = null;
    if (props.environment) {
      isLoading.value = true;
      try {
        await disconnectStripeEnvironment(
          store.currentOrganization.key,
          props.environment.key as string
        );
        await store.fillEnvironments(true);
        await router.replace({ query: undefined });
      } catch (err) {
        displayErrorMessage.value = i18n.global.t(
          "errorMessage.actionNotPerformed"
        );
      } finally {
        isLoading.value = false;
      }
    }
  }
}

const confirmDisconnect = () => {
  confirm.require({
    group: "disconnect-stripe",
    message: i18n.global.t("confirmations.stripe.message"),
    header: i18n.global.t("confirmations.stripe.header"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
  });
};
async function checkStripeConnection() {
  displayErrorMessage.value = null;
  if (
    route.query.stripeCode &&
    !props.environment?.test_stripe_user_id &&
    !props.environment?.stripe_user_id
  ) {
    const stripe_auth_code = route.query.stripeCode as string;
    const environment_id = route.query.environmentKey as string;
    if (stripe_auth_code || environment_id) {
      isLoading.value = true;
      try {
        await updateStripeEnvironment(
          store.currentOrganization.key,
          environment_id,
          stripe_auth_code
        );
        await store.fillEnvironments(true);
      } catch (err) {
        displayErrorMessage.value = i18n.global.t(
          "errorMessage.connectionStatus"
        );
      } finally {
        isLoading.value = false;
      }
    }
  }

  if (store.hasPermission("gateway.read")) {
    stripeConnectGateways.value = await stripeConnectGatewaysList(
      store.currentOrganization.key,
      store.currentEnvironment.key as string
    );
  }
  setConnectStripeTestEnvironment();
}

function setConnectStripeTestEnvironment() {
  // If stripe_user_id is set, we'll consider this production.
  // Otherwise, it is "test", which is the default.
  connectStripeTestEnvironment.value =
    store.currentEnvironment.stripe_user_id === null;
}
</script>
