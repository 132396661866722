<template>
  <div v-if="!isLoading">
    <Suspense>
      <component :is="layout"></component>
      <template #fallback>
        <SpreedlyLoadingSpinner />
      </template>
    </Suspense>
  </div>
</template>

<script setup lang="ts">
import { useLayout } from "@/composables/useLayout";
import { useAuth0 } from "@auth0/auth0-vue";
import { computed, markRaw } from "vue";
import InternalErrorLayout from "@/components/layouts/InternalErrorLayout.vue";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";

const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
const { isInternalError } = useLayout();

const layout = computed(() => {
  if (!isLoading.value && isAuthenticated.value) {
    if (isInternalError.value) {
      return markRaw(InternalErrorLayout);
    }
    return markRaw(DefaultLayout);
  } else if (!isLoading.value && !isAuthenticated.value) {
    loginWithRedirect();
  }
});
</script>
