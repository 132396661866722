import { fetchTransaction } from "@/services/TransactionService";
import { fetchGateway } from "./GatewayService";
import type { Environment } from "@/services/EnvironmentService";
import type { User } from "@/services/UserService";

export const TRANSACTION_TOKEN = "transactionToken";
export const GATEWAY_TOKEN = "gatewayToken";

export type SearchResult = {
  token: string;
  environment?: { key: string; name: string };
  organization?: { key: string };
};

export type RecentSearch = {
  transactionToken: SearchResult[];
  gatewayToken: SearchResult[];
};

export async function searchAllEnvironmentsByTxnToken(
  orgKey: string,
  envKey: string,
  txnToken: string,
  environments: Environment[],
  user: User
): Promise<SearchResult | undefined> {
  const searchPromise = fetchTransaction(orgKey, envKey, txnToken)
    .then((result) => {
      const env = environments.find((e) => e.key === result.environment_key);
      if (env) {
        return {
          token: txnToken,
          environment: { key: env.key, name: env.name },
        } as SearchResult;
      }
      if (user.spreedlyAdmin && result?.token) {
        return {
          token: txnToken,
          organization: { key: result?.organization_key },
        } as SearchResult;
      }
      return undefined;
    })
    .catch(() => {
      return undefined;
    });

  return await searchPromise;
}

export async function searchAllEnvironmentsByGatewayToken(
  orgKey: string,
  envKey: string,
  gatewayToken: string,
  environments: Environment[],
  user: User
): Promise<SearchResult | undefined> {
  const searchPromise = fetchGateway(orgKey, envKey, gatewayToken)
    .then((result) => {
      const env = environments.find((e) => e.key === result?.environment_key);
      if (env) {
        return {
          token: gatewayToken,
          environment: { key: env.key, name: env.name },
        } as SearchResult;
      }
      if (user.spreedlyAdmin && result?.key) {
        return {
          token: gatewayToken,
          organization: { key: result?.organization_key },
        } as SearchResult;
      }
      return undefined;
    })
    .catch(() => {
      return undefined;
    });

  return await searchPromise;
}
