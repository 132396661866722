import http, { get, put, getList, post } from "@/http";
import * as Sentry from "@sentry/vue";
import i18n from "@/i18n";

export type WorkflowPayload = {
  workflow: {
    key?: string;
    environment_key?: string;
    name: string;
    created_at?: string;
    updated_at?: string;
    version?: number;
    deleted?: boolean;
    steps?: WorkflowStep[];
    sandbox?: boolean;
  };
};

export type WorkflowStep = {
  conditions?: WorkflowCondition;
  steps?: WorkflowStep[];
  conclusions?: WorkflowConclusion[];
};

type WorkflowCondition = {
  condition: string;
  values: string[];
};

export type WorkflowConclusion = {
  weight: number;
  result: WorkflowResult;
};

export type WorkflowResult = {
  gateway_key: string;
  gateway_type: string;
  parent_company_id: string;
};

export type Workflow = {
  key: string;
  deleted: boolean;
  inserted_at: string;
  updated_at: string;
  name: string;
  steps: WorkflowStep[];
  sandbox: boolean;
};

export async function createWorkflow(
  orgKey: string,
  envKey: string,
  workflow: WorkflowPayload
): Promise<Workflow> {
  const url = `/organizations/${orgKey}/environments/${envKey}/workflows`;
  return post(url, workflow, "workflow");
}

export async function listWorkflowsByGateway(
  orgKey: string,
  envKey: string,
  gatewayId: string
) {
  return getList<Workflow>(
    `/organizations/${orgKey}/environments/${envKey}/workflows/workflows_by_gateway/${gatewayId}`,
    "workflows"
  );
}
export async function listWorkflows(orgKey: string, envKey: string) {
  return getList<Workflow>(
    `/organizations/${orgKey}/environments/${envKey}/workflows`,
    "workflows"
  );
}

export async function updateWorkflow(
  orgKey: string,
  envKey: string,
  workflowKey: string,
  workflow: WorkflowPayload
): Promise<Workflow | null> {
  const url = `/organizations/${orgKey}/environments/${envKey}/workflows/${workflowKey}`;
  return put(url, workflow, "workflow");
}

export async function deleteWorkflow(
  orgKey: string,
  envKey: string,
  workflowKey: string
): Promise<boolean> {
  const url = `/organizations/${orgKey}/environments/${envKey}/workflows/${workflowKey}`;
  try {
    const response = await http().delete(url);
    return response.data.workflow.deleted;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
}

export async function fetchWorkflow(
  orgKey: string,
  envKey: string,
  workflowKey: string
): Promise<Workflow | null> {
  return get(
    `/organizations/${orgKey}/environments/${envKey}/workflows/${workflowKey}`,
    "workflow"
  );
}

export function getGatewayParentCompanies(
  workflowSteps: WorkflowStep[]
): Set<string> {
  const defaults = getDefaults(workflowSteps);

  return new Set(
    defaults?.map((conclusion: WorkflowConclusion) => {
      return conclusion.result.parent_company_id;
    })
  );
}

export function getDefaults(
  workflowSteps: WorkflowStep[]
): WorkflowConclusion[] {
  return workflowSteps.find((step: WorkflowStep) => {
    const keys = Object.keys(step);
    return keys.includes("conclusions") && !keys.includes("steps");
  })!.conclusions as WorkflowConclusion[];
}

export function getConditions(workflowSteps: WorkflowStep[]): string[] | [] {
  const defaults = getDefaults(workflowSteps);

  if (defaults && defaults.length > 1) {
    return [i18n.global.t("workflows.splitRouting")];
  }
  return [];
}
