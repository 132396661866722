import { computed, onMounted, onUnmounted, ref } from "vue";

export type Breakpoint = "sm" | "md" | "lg" | "xl" | "2xl";
export const useBreakpoints = () => {
  const windowWidth = ref(window.innerWidth);

  const onWidthChange = () => (windowWidth.value = window.innerWidth);
  onMounted(() => window.addEventListener("resize", onWidthChange));
  onUnmounted(() => window.removeEventListener("resize", onWidthChange));

  const breakpoint = computed<Breakpoint>(() => {
    if (windowWidth.value < 480) return "sm";
    if (windowWidth.value >= 480 && windowWidth.value < 768) return "md";
    if (windowWidth.value >= 768 && windowWidth.value < 1024) return "lg";
    if (windowWidth.value >= 1024 && windowWidth.value < 1720) return "xl";
    else return "2xl"; // Fires when windowWidth.value >= 1720
  });

  const width = computed(() => windowWidth.value);

  return { width, breakpoint };
};
