<template>
  <div>
    <SpreedlyLookerEmbed
      :dashboard-id="398"
      id="looker-embed-api-calls"
      :org-view="props.orgView"
    />
    <SpreedlyLookerEmbed
      :dashboard-id="397"
      id="looker-embed-account-overview"
      :org-view="props.orgView"
    />
    <SpreedlyLookerEmbed
      :dashboard-id="4"
      id="looker-embed-success-rates"
      :org-view="props.orgView"
    />
  </div>
</template>

<script setup lang="ts">
import SpreedlyLookerEmbed from "@/components/SpreedlyLookerEmbed.vue";
const props = defineProps<{
  orgView?: boolean;
}>();
</script>
