import type { Gateway } from "./gateway.model";
import type { DimensionOption } from "./dimension-option";

export interface Options {
  retryable_gateways?: string[];
  retry_options: typeof RetryOptions;
  gateways: Gateway[];
  card_brands: DimensionOption[];
  card_categories: DimensionOption[];
  card_types: DimensionOption[];
  country_codes: DimensionOption[];
  currency_codes: DimensionOption[];
}

export enum ERetryOptions {
  null = "None",
  standard = "Standard",
  outage_only = "Outage Only",
}

export const RetryOptions: DimensionOption[] = [
  { text: ERetryOptions.null, value: null },
  { text: ERetryOptions.standard, value: "standard" },
  { text: ERetryOptions.outage_only, value: "outage_only" },
];
