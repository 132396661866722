<template>
  <SpreedlyLookerEmbed
    :dashboard-id="14"
    id="looker-embed-payment-methods"
    :org-view="props.orgView"
  />
  <SpreedlyLookerEmbed
    :dashboard-id="126"
    id="looker-embed-nt-provisioning"
    :org-view="props.orgView"
  />
  <SpreedlyLookerEmbed
    :dashboard-id="125"
    id="looker-embed-nt-transactions"
    :org-view="props.orgView"
  />
</template>
<script setup lang="ts">
import SpreedlyLookerEmbed from "@/components/SpreedlyLookerEmbed.vue";
const props = defineProps<{
  orgView?: boolean;
}>();
</script>
