import { http, getList, post, put } from "@/http";
import * as Sentry from "@sentry/vue";

import type { EnvironmentGateway } from "@/services/GatewayService";
import type { Organization } from "@/services/OrganizationService";

export interface Environment {
  name: string;
  payment_method_submission: string;
  key?: string;
  callback_url?: string | null;
  au_enabled?: boolean;
  signing_secret?: string;
  created_at?: string;
  stripe_user_id?: string | null;
  test_stripe_user_id?: string | null;
  payment_method_management_enabled?: boolean;
  default_workflow_key?: string | null;

  gateways?: EnvironmentGateway[];
}

export async function updateEnvironmentDefaultWorkflow(
  orgKey: string,
  environment: Environment
): Promise<Environment | null> {
  const url = `/organizations/${orgKey}/environments/${environment.key}/update_default_workflow`;
  try {
    const response = await http().put(url, environment);
    return response.data.environment;
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
}

export async function updateEnvironment(
  orgKey: string,
  environment: Environment
): Promise<Environment | null> {
  const url = `/organizations/${orgKey}/environments/${environment.key}`;
  if (environment.payment_method_submission) {
    environment.payment_method_submission = formatPaymentMethodSubmission(
      environment.payment_method_submission
    );
  }
  return put(url, environment, "environment");
}

export async function regenerateSigningSecret(
  orgKey: string,
  envKey?: string
): Promise<boolean> {
  const url = `/organizations/${orgKey}/environments/${envKey}/regenerate_signing_secret`;
  try {
    const response = await http().put(url);
    return !!response.data.environment;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
}

export async function createEnvironment(
  orgKey: string,
  environment: Environment
): Promise<Environment | null> {
  const url = `/organizations/${orgKey}/environments/`;

  if (environment.payment_method_submission) {
    environment.payment_method_submission = formatPaymentMethodSubmission(
      environment.payment_method_submission
    );
  }
  return post(url, environment, "environment");
}

export async function fetchEnvironments(
  orgKey: string
): Promise<Environment[]> {
  return getList<Environment>(
    `/organizations/${orgKey}/environments`,
    "environments"
  ).then((environments) => {
    // TODO: Move default sorting and name changes to Id or component(s).
    return environments
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((environment) => {
        environment.payment_method_submission = formatPaymentMethodSubmission(
          environment.payment_method_submission
        );
        return environment;
      });
  });
}

export function determineEnvironmentAdvancedVaultStatus(
  org: Organization,
  envAvStatus: boolean
) {
  if (
    !org.allow_payment_method_management ||
    !org.payment_method_management_enabled
  ) {
    return false;
  } else if (
    org.allow_payment_method_management &&
    org.payment_method_management_enabled &&
    envAvStatus
  ) {
    return true;
  } else {
    return envAvStatus;
  }
}

export function determineEnvironmentAccountUpdaterStatus(
  org: Organization,
  envAuStatus: boolean
) {
  // Depending on org level AU status, env should appear either on or off:
  // 1: if allow_au is off then env should appear off
  // 2: if org au is on and allow env level is off then env should appear on
  // 3: if org au is off then env should appear off
  // 4: if org au is on and allow env level is on then user should toggle env level off or on

  if (!org.allow_account_updater || !org.account_updater_enabled) {
    return false;
  } else if (
    // Should display env au as ON if org au is enabled and org allow au by environment is disabled
    org.allow_account_updater &&
    org.account_updater_enabled &&
    !org.allow_au_by_environment
  ) {
    return true;
  } else {
    //if org allow au by env is enabled then default to environment au status
    return envAuStatus;
  }
}

function formatPaymentMethodSubmission(paymentMethodSubmission: string) {
  switch (paymentMethodSubmission) {
    case "Spreedly Express or iFrame Only":
      return "iFrame or Spreedly Express";
    case "iFrame or Spreedly Express":
      return "Spreedly Express or iFrame Only";
    default:
      return paymentMethodSubmission;
  }
}
