<template>
  <button
    type="button"
    class="max-h-[42px] inline-flex disabled:cursor-not-allowed justify-center items-center"
    :class="buttonClass"
    :disabled="disabled"
    :title="title"
  >
    <SpreedlyIcon :name="name"></SpreedlyIcon>
  </button>
</template>
<script setup lang="ts">
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";
import { computed } from "vue";

const props = defineProps<{
  name: string;
  title: string;
  size?: "xs" | "sm";
  inverse?: boolean;
  disabled?: boolean;
  basic?: boolean;
}>();

const buttonClass = computed(() => {
  return `${calculateSize()} ${calculateColors()}`;
});

function calculateSize() {
  switch (props.size) {
    case "xs":
      return "px-2";
    case "sm":
      return "px-3 py-1";
    default:
      return "px-4 py-2";
  }
}

function calculateColors() {
  if (props.basic) {
    return;
  }
  if (props.inverse) {
    return "bg-white text-spreedly-blue-600 border-spreedly-blue-600 disabled:text-spreedly-gray-400 disabled:border-spreedly-gray-200 disabled:bg-white hover:bg-blue-50 hover:border-spreedly-blue-600 active:bg-blue-50 active:border-spreedly-blue-600 focus:bg-blue-50 focus:border-spreedly-blue-600 rounded border";
  } else {
    return "bg-spreedly-blue-600 text-white border-spreedly-blue-600 disabled:text-spreedly-gray-400 disabled:border-spreedly-gray-200 disabled:bg-spreedly-gray-200 hover:bg-spreedly-blue-700 hover:border-spreedly-blue-700 active:bg-spreedly-blue-700 active:border-spreedly-blue-700 focus:bg-spreedly-blue-700 focus:border-spreedly-blue-700 rounded border";
  }
}
</script>
