import { useOptionsStore } from "@/stores/routing-rules/OptionsStore";
import i18n from "@/i18n";
import type { Options } from "@/models/routing-rules/options.model";

export default {
  // supports dot-notation, e.g. getDim('amount.usd_amount')
  // h/t https://stackoverflow.com/a/45322101
  getDim(dim) {
    return dim
      .split(".")
      .reduce((prev, curr) => (prev ? prev.dimensions[curr] : null), this.data);
  },
  getDimDisplayOrder() {
    return this.data.dim_display_order;
  },
  getOption(dim, optVal) {
    return this.getDim(dim).options.find((opt) => opt.value === optVal);
  },
  getSimpleDimensions() {
    return Object.entries(this.data.dimensions)
      .filter(
        ([_key, val]) => Array.isArray(val.default) && val.default.length === 0
      )
      .map(([key, _val]) => key);
  },
  getActiveDimensionsForRule(rule) {
    return this.getDimDisplayOrder().filter((dim) => rule[dim]);
  },
  getInactiveDimensionsForRule(rule) {
    return this.getDimDisplayOrder().filter((dim) => !rule[dim]);
  },

  setData() {
    function isOptions(opts?: Options): opts is Options {
      return opts?.card_brands !== undefined;
    }
    const options = useOptionsStore().options; // TODO: DimOptionsticket why is this never[]????
    if (isOptions(options)) {
      this.data.dimensions.card_category.options = options.card_categories;
      this.data.dimensions.card_brand.options = options.card_brands;
      this.data.dimensions.card_type.options = options.card_types;
      this.data.dimensions.country.options = options.country_codes;
      this.data.dimensions.transaction_currency.options =
        options.currency_codes;
    }
  },
  data: {
    dim_display_order: [
      "amount",
      "bin_ranges",
      "card_brand",
      "card_category",
      "card_type",
      "country",
      "transaction_currency",
      "metadata",
    ],
    dimensions: {
      amount: {
        text: i18n.global.t("routing.rule.dim.amount"),
        dimensions: {
          usd_amount: {},
          usd_amount_operator: {
            options: [
              {
                value: ">=",
                text: "min",
              },
              {
                value: "<=",
                text: "max",
              },
            ],
          },
        },
        default: {
          usd_amount: 0,
          usd_amount_operator: ">=",
        },
      },
      bin_ranges: {
        text: i18n.global.t("routing.rule.dim.binRanges"),
        options: [],
        default: [{ bin_lower: null, bin_upper: null }],
      },
      card_brand: {
        text: i18n.global.t("routing.rule.dim.cardBrand"),
        options: [],
        default: [],
      },
      card_category: {
        text: i18n.global.t("routing.rule.dim.cardCategory"),
        options: [],
        default: [],
      },
      card_type: {
        text: i18n.global.t("routing.rule.dim.cardType"),
        options: [],
        default: [],
      },
      country: {
        text: i18n.global.t("routing.rule.dim.country"),
        options: [],
        default: [],
      },
      metadata: {
        text: i18n.global.t("routing.rule.dim.metadata"),
        options: [],
        default: [[]],
      },
      transaction_currency: {
        text: i18n.global.t("routing.rule.dim.currency"),
        options: [],
        default: [],
      },
    },
  },
};
