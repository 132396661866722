<template>
  <div
    v-if="store.hasComposerEnabledGatewayConnections()"
    class="flex flex-row mt-2 pb-4"
    :class="{ '!w-[390px]': width <= 570, '!w-[365px]': width <= 500 }"
  >
    <div class="flex flex-col w-[80px]" v-if="gatewayConclusions.length > 1">
      <label
        class="text-sm pb-2"
        :class="{
          'p-error': v.weight.$invalid && submitted,
        }"
        :for="`workflow-split-${index}`"
        >{{ `${$t("workflows.split")} % ` }}</label
      >

      <InputNumber
        v-model="v.weight.$model"
        buttonLayout="vertical"
        :inputId="`workflow-split-${index}`"
        :disabled="!hasEditPermission()"
        @update:model-value="updateParent()"
        mode="decimal"
        :pt="{
          root: {
            class:
              '!h-[80px] !w-[80px] border border-gray-300 border-1 rounded-lg px-[10px]',
          },
        }"
        showButtons
        :min="0"
        :max="100"
      />
      <button
        :class="{ hidden: width >= 650 }"
        type="button"
        @click="$emit('removeSplit', index)"
        :disabled="!hasEditPermission()"
        class="mb-4 text-spreedly-blue-600 hover:text-spreedly-blue-dark underline"
      >
        {{ $t("remove") }}
      </button>
    </div>
    <div
      class="flex flex-col px-2 lg:px-4"
      :class="gatewayConclusions.length > 1 ? 'w-[calc(100%-80px)]' : 'w-full'"
    >
      <label
        class="text-sm pb-2"
        :class="{
          'p-error': v.result.gateway_key.$invalid && submitted,
        }"
        :for="`workflow-gateway-dropdown-${index}`"
        >{{ $t("gateway") }}</label
      >
      <div>
        <div class="flex justify-between flex-row">
          <Dropdown
            :disabled="!hasEditPermission()"
            :id="`workflow-gateway-dropdown-${index}`"
            :loading="loadingGateways"
            :show-clear="!!v.result.gateway_key.$model"
            v-model="v.result.gateway_key.$model"
            @change="updateParent()"
            :options="gateways"
            :option-disabled="disableOption"
            option-value="key"
            :pt="{ root: { class: '!h-[80px] min-w-[270px] ' } }"
          >
            <template #value>
              <div
                class="flex align-items-center !font-sans"
                v-if="v.result.gateway_key.$model"
              >
                <div
                  class="flex flex-col text-sm min-w-0"
                  v-if="selectedGateway"
                >
                  <div class="flex flex-row">
                    <div v-if="selectedGateway.description">
                      {{ selectedGateway.description }}
                    </div>
                    <div
                      class="self-center"
                      v-if="
                        selectedGateway.sandbox ||
                        selectedGateway.gateway_type === 'test'
                      "
                    >
                      <div
                        class="border border-success-green bg-success-green-light text-spreedly-gray-600 rounded-xl px-2 mr-2 text-xs"
                        :class="selectedGateway.description ? 'mx-4' : 'mx-0'"
                      >
                        {{ $t("sandbox") }}
                      </div>
                    </div>
                  </div>
                  <div class="overflow-hidden text-ellipsis whitespace-nowrap">
                    {{ sentenceCase(selectedGateway.gateway_type) }}
                  </div>
                  <div
                    class="overflow-hidden text-ellipsis whitespace-nowrap"
                    :title="selectedGateway.key"
                  >
                    {{ `${$t("token")}: ${selectedGateway.key}` }}
                  </div>
                  <!--description does not always exist, when it doesn't there needs to be a space holder so the
                     dropdown doesn't jump on the screen-->
                  <div
                    v-if="
                      !selectedGateway.description && !selectedGateway.sandbox
                    "
                  >
                    {{ "&nbsp;" }}
                  </div>
                </div>
              </div>
              <div v-else></div>
            </template>
            <template #option="slotProps">
              <div class="flex flex-col text-sm !font-sans">
                <div class="flex flex-row">
                  <div>
                    {{ slotProps.option.description }}
                  </div>
                  <div
                    class="self-center"
                    v-if="
                      slotProps.option.sandbox ||
                      slotProps.option.gateway_type === 'test'
                    "
                  >
                    <div
                      class="border border-success-green bg-success-green-light text-spreedly-gray-600 rounded-xl px-2 mr-2 text-xs"
                      :class="slotProps.option.description ? 'mx-4' : 'mx-0'"
                    >
                      {{ $t("sandbox") }}
                    </div>
                  </div>
                </div>
                <div>
                  {{ sentenceCase(slotProps.option.gateway_type) }}
                </div>
                <div>
                  {{ `${$t("token")}: ${slotProps.option.key}` }}
                </div>
              </div>
            </template>
          </Dropdown>
          <button
            v-if="gatewayConclusions.length > 1"
            type="button"
            @click="$emit('removeSplit', index)"
            :disabled="!hasEditPermission()"
            class="ml-4 mb-4 text-spreedly-blue-600 hover:text-spreedly-blue-700 underline disabled:text-spreedly-gray-400"
            :class="{ hidden: width < 650 }"
          >
            {{ $t("remove") }}
          </button>
        </div>

        <div v-if="v.result.gateway_key.$invalid && submitted" class="-mt-4">
          <small class="p-error">{{
            v.result.gateway_key.required.$message
          }}</small>
        </div>
      </div>
    </div>
  </div>
  <Message
    v-else
    severity="info"
    :closable="false"
    :pt="{ root: { class: 'bg-spreedly-blue-200' } }"
  >
    <i18n-t
      :keypath="'workflows.noGateways'"
      tag="p"
      class="text-lg"
      scope="global"
    >
      <template v-slot:supportedGateway>
        <router-link
          class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
          :to="{ name: 'Marketplace' }"
          >{{ $t("workflows.supportedGateway") }}
        </router-link>
      </template>
    </i18n-t>
  </Message>
</template>

<script setup lang="ts">
import { computed, defineModel, reactive, watch } from "vue";
import { sentenceCase } from "@/services/HelperService";
import type {
  GatewayConnection,
  GatewayOption,
} from "@/services/GatewayService";
import { useSettingsStore } from "@/stores/SettingsStore";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import Message from "primevue/message";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useGatewayConclusions } from "@/composables/useGatewayConclusions";
import type { GatewayConclusion } from "@/composables/useGatewayConclusions";
import { useBreakpoints } from "@/composables/useBreakpoints";

const reset = defineModel("reset", { required: true });
const { gatewayConclusions, loadingGateways, gateways } =
  useGatewayConclusions();

const props = defineProps<{
  action: "create" | "update";
  gateway: GatewayConclusion;
  index: number;
  submitted: boolean;
}>();
const { width } = useBreakpoints();

const store = useSettingsStore();

const emit = defineEmits(["updateGateway", "removeSplit"]);

function hasEditPermission() {
  switch (props.action) {
    case "create":
      return store.hasPermission("organization.create_workflow");
    case "update":
      return store.hasPermission("workflow.update");
    default:
      return false;
  }
}

const selectedGateway = computed(() => {
  let selectedConnection = gateways.value.find(
    (g) => g.key === state.result.gateway_key
  );
  if (!selectedConnection) {
    state.result.gateway_key = "";
    state.result.gateway_type = "";
    state.result.parent_company_id = "";
    return null;
  }
  return selectedConnection;
});

const state = reactive(props.gateway);

const rules = {
  result: {
    gateway_key: { required },
    gateway_type: {},
    parent_company_id: {},
  },
  weight: { required },
};

const v = useVuelidate(rules, state);

function updateParent() {
  if (selectedGateway.value?.gateway_type) {
    v.value.$touch();
    state.result.gateway_type = selectedGateway.value?.gateway_type;
    state.result.parent_company_id = getGatewayPartner(
      selectedGateway.value?.gateway_type
    );
    emit("updateGateway", state, props.index);
  }
}

const getGatewayPartner = (gatewayType: string) => {
  return (
    store.gatewayOptions.find(
      (g: GatewayOption) => g.gateway_type === gatewayType
    )?.partner || ""
  );
};

function populateState() {
  if (gateways.value && gateways.value.length) {
    state.result.gateway_key = props.gateway.result.gateway_key;
    state.result.gateway_type = props.gateway.result.gateway_type;
    state.result.parent_company_id = props.gateway.result.parent_company_id;
  }
}

const disableOption = (option: GatewayConnection) => {
  return gatewayConclusions.value
    .map((g) => g.result.gateway_key)
    .includes(option.key);
};

watch(reset, () => {
  if (reset.value) {
    populateState();
    reset.value = false;
  }
});

watch(selectedGateway, () => {
  if (!selectedGateway.value) {
    state.result.gateway_key = "";
    state.result.gateway_type = "";
    state.result.parent_company_id = "";
  }
});
</script>
