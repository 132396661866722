<template>
  <div>
    <SpreedlyLookerEmbed
      :dashboard-id="33"
      id="looker-embed-transactions"
      :org-view="props.orgView"
    />
    <SpreedlyLookerEmbed
      id="looker-embed-environment-api-calls"
      :dashboard-id="42"
      :org-view="props.orgView"
    />
  </div>
</template>
<script setup lang="ts">
import SpreedlyLookerEmbed from "@/components/SpreedlyLookerEmbed.vue";
const props = defineProps<{
  orgView?: boolean;
}>();
</script>
