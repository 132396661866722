import { ref } from "vue";

const isOpen = ref(false);
const isEnvironmentMenu = ref(false);
const isConnectivityMenu = ref(false);
const isPerformanceMenu = ref(false);

export function useSidebar() {
  return {
    isOpen,
    isEnvironmentMenu,
    isConnectivityMenu,
    isPerformanceMenu,
    setEnvironmentDrawerState,
    setPerformanceDrawerState,
    setConnectivityDrawerState,
    recallDrawerState,
  };
}

export const setEnvironmentDrawerState = (isOpen: boolean) => {
  sessionStorage.setItem("environment-drawer", JSON.stringify(isOpen));
};

export const setPerformanceDrawerState = (isOpen: boolean) => {
  sessionStorage.setItem("performance-drawer", JSON.stringify(isOpen));
};

export const setConnectivityDrawerState = (isOpen: boolean) => {
  sessionStorage.setItem("connectivity-drawer", JSON.stringify(isOpen));
};
export const recallDrawerState = () => {
  if (sessionStorage.getItem("environment-drawer")) {
    isEnvironmentMenu.value = JSON.parse(
      sessionStorage.getItem("environment-drawer") || "false"
    );
  }

  if (sessionStorage.getItem("connectivity-drawer")) {
    isConnectivityMenu.value = JSON.parse(
      sessionStorage.getItem("connectivity-drawer") || "false"
    );
  }

  if (sessionStorage.getItem("performance-drawer")) {
    isPerformanceMenu.value = JSON.parse(
      sessionStorage.getItem("performance-drawer") || "false"
    );
  }
};
