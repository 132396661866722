<template>
  <div id="branded-background" class="w-full h-full text-spreedly-gray-500">
    <div class="flex flex-col relative top-1/3 w-full text-center">
      <h1 class="text-4xl lg:text-6xl">404</h1>
      <h2 class="text-xl lg:text-3xl">{{ $t("notFound.header") }}</h2>
      <h3 class="text-base lg:text-lg">
        {{ $t("notFound.subheader") }}
      </h3>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
<style lang="css" scoped>
#branded-background {
  background-image: url("@/assets/logos/flock-of-spreedly.svg");
  background-position: center;
  background-repeat: no-repeat;
}
</style>
