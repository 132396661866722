<template>
  <div>
    <SpreedlyPageTitle
      :title="$t('workflows.new')"
      breadcrumbs
      :tag="defaultTag"
    >
      <template v-slot:breadcrumbs>
        <router-link
          :to="{
            name: 'WorkflowList',
          }"
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
        >
          <i class="pi pi-arrow-left relative mr-1 pb-4 text-[0.75rem]"></i>
          {{ $t("composer.title") }}
        </router-link>
      </template>
    </SpreedlyPageTitle>
    <SpreedlySubtitle
      :title="$t('workflows.designer')"
      class="mb-4"
    ></SpreedlySubtitle>
    <ConfirmDialog group="sandbox-warning" id="warningDialog"></ConfirmDialog>
    <div class="flex flex-row w-full h-[calc(100vh-200px)]">
      <SpreedlyContainer class="h-full !mb-0 !pb-0 w-full">
        <Message
          v-if="displayErrorMessage"
          class="-mx-8 -mt-8 w-[calc(100% + 4rem)] justify-start"
          severity="error"
          :closable="false"
          >{{ displayErrorMessage }}
        </Message>
        <div class="flex flex-col justify-between h-full">
          <div class="flex flex-row">
            <form
              class="relative"
              :class="width >= 800 ? 'w-[calc(100%-209.56px)]' : 'w-[95%]'"
              @submit.prevent="save(v$.$invalid)"
            >
              <SpreedlyVerticalStepper :step-text="$t('workflows.start')">
                <div class="field">
                  <label
                    class="text-sm"
                    :class="{
                      'p-error': v$.name.$invalid && submitted,
                    }"
                    for="workflow-name-input"
                    >{{ $t("workflows.name") }}</label
                  >
                  <div class="mt-2 pb-4 w-[300px]">
                    <InputText
                      class="mx-2 w-full"
                      maxlength="50"
                      v-model.trim="v$.name.$model"
                      id="workflow-name-input"
                      type="text"
                      :disabled="
                        !store.hasPermission('organization.create_workflow')
                      "
                    />
                    <div v-if="v$.name.$invalid && submitted" class="mt-0">
                      <small class="p-error">{{
                        v$.name.required.$message
                      }}</small>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row">
                  <div class="self-center">
                    <Checkbox
                      v-model="v$.sandbox.$model"
                      input-id="workflow-sandbox-checkbox"
                      variant="outlined"
                      @update:model-value="
                        confirmSandboxToggle(v$.sandbox.$model)
                      "
                      binary
                      :disabled="
                        !store.hasPermission('organization.create_workflow')
                      "
                    />
                  </div>

                  <label
                    for="workflow-sandbox-checkbox"
                    class="self-center ml-2"
                  >
                    {{ $t("workflows.sandboxMode") }}
                  </label>
                  <mdicon
                    name="information-outline"
                    class="text-spreedly-blue-700 px-2 hover:cursor-pointer"
                    data-testid="info-icon"
                    v-tooltip="$t('workflows.sandboxModeInfo')"
                  ></mdicon>
                </div>
              </SpreedlyVerticalStepper>
              <SpreedlyVerticalStepper
                :step-text="$t('workflows.end')"
                :content-header="$t('gateway')"
              >
                <WorkflowGatewayConclusion
                  v-for="(gateway, index) in gatewayConclusions"
                  :key="gateway.uuid"
                  action="create"
                  :gateway="gateway"
                  :index="index"
                  :submitted="submitted"
                  v-model:reset="resetChildrenForms"
                  @update-gateway="updateGatewayConclusion"
                  @remove-split="removeSplit"
                ></WorkflowGatewayConclusion>
                <button
                  v-if="gatewayConclusions.length === 1 && gateways?.length > 1"
                  type="button"
                  class="text-spreedly-blue-600 hover:text-spreedly-blue-700 add-gateway-split-button"
                  @click="addGatewaySplit()"
                >
                  <i class="pi pi-plus relative mr-1 pb-4 text-[0.75rem]"></i>
                  {{ $t("workflows.addSplit") }}
                </button>
              </SpreedlyVerticalStepper>
            </form>

            <Button
              v-if="!togglePaymentCapabilities && width < 800"
              @click="togglePaymentCapabilities = true"
              :title="$t('viewCapabilities')"
              :aria-label="$t('viewCapabilities')"
              id="view-payment-capabilities"
              icon="pi pi-list"
              class="danger p-button-text p-button-rounded !shadow-none relative inset-y-0"
            ></Button>
            <div v-else-if="!togglePaymentCapabilities && width >= 800">
              <SpreedlyButton
                id="view-payment-capabilities"
                :text="$t('viewCapabilities')"
                inverse
                @click="togglePaymentCapabilities = true"
              ></SpreedlyButton>
            </div>
          </div>
          <div class="w-full flex flex-row justify-start pt-8 pb-8">
            <SpreedlyButton
              class="mr-4"
              :text="$t('reset')"
              id="cancel-create-workflow-button"
              :inverse="true"
              :disabled="disableForm()"
              @click="resetForm()"
            ></SpreedlyButton>
            <SpreedlyButton
              :disabled="disableForm()"
              class="!mr-0"
              id="create-workflow-button"
              :icon="{ position: 'left', state: formState }"
              :text="$t(formState)"
              @click="save(v$.$invalid)"
            ></SpreedlyButton>
          </div>
        </div>
      </SpreedlyContainer>
      <PaymentCapabilitiesDrawer
        :composer="true"
        v-model:toggle-payment-capabilities="togglePaymentCapabilities"
      ></PaymentCapabilitiesDrawer>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlySubtitle from "@/components/SpreedlySubtitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyVerticalStepper from "@/components/SpreedlyVerticalStepper.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import WorkflowGatewayConclusion from "@/components/WorkflowGatewayConclusion.vue";

import Message from "primevue/message";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import PaymentCapabilitiesDrawer from "@/components/PaymentCapabilitiesDrawer.vue";

import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue";

import i18n from "@/i18n";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import router from "@/router";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { createWorkflow } from "@/services/WorkflowService";
import { onBeforeRouteLeave } from "vue-router";
import {
  addGatewaySplit,
  formatWorkflowConclusion,
  setGateways,
  removeSplit,
  resetGatewayConclusion,
  resetGateways,
  updateGatewayConclusion,
  useGatewayConclusions,
} from "@/composables/useGatewayConclusions";
import { updateEnvironment } from "@/services/EnvironmentService";
import { deepCopy } from "@/services/HelperService";
import { useBreakpoints } from "@/composables/useBreakpoints";
import Checkbox from "primevue/checkbox";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";

const displayErrorMessage = ref<string | null>(null);
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const store = useSettingsStore();
const { currentEnvironment, currentOrganization } = storeToRefs(store);
const { gatewayConclusions, gateways } = useGatewayConclusions();
const togglePaymentCapabilities = ref(false);

const resetChildrenForms = ref(false);
const { width } = useBreakpoints();

onMounted(async () => {
  await populateState();
});

onUnmounted(() => {
  resetGateways();
});

const confirm = useConfirm();

async function populateState() {
  await setGateways(
    currentOrganization.value.key,
    currentEnvironment.value.key!,
    state.sandbox
  );
  resetGatewayConclusion();
}

const state = reactive({
  name: "",
  sandbox: false,
});

const rules = {
  name: { required },
  sandbox: {},
};

const submitted = ref(false);
const v$ = useVuelidate(rules, state);

const defaultTag = computed(() => {
  return {
    displayTag: !currentEnvironment.value.default_workflow_key,
    severity: "composer",
    value: i18n.global.t("workflows.default"),
  };
});

watch(
  () => currentEnvironment.value.key,
  () => {
    router.push({
      name: "WorkflowList",
    });
  }
);

watch(
  () => v$.value.$anyDirty,
  () => {
    if (v$.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

onBeforeRouteLeave((to, from) => {
  if (v$.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

async function save(isFormInvalid: boolean) {
  if (!v$.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = null;

  if (!store.hasPermission("organization.create_workflow")) {
    displayErrorMessage.value = i18n.global.t("permission_denied_edit");
    return;
  }

  submitted.value = true;
  if (isFormInvalid) {
    return;
  }

  try {
    formState.value = "saving";
    const response = await createWorkflow(
      currentOrganization.value.key,
      currentEnvironment.value.key as string,
      formatPayload()
    );

    if (response) {
      if (!currentEnvironment.value.default_workflow_key) {
        let env = deepCopy(store.currentEnvironment);
        env.default_workflow_key = response.key;
        await updateEnvironment(currentOrganization.value.key, env);
        await store.fillEnvironments(true);
      }
      formState.value = "saved";
      v$.value.$reset();
      await router.push({
        name: "WorkflowSettings",
        params: {
          id: response.key,
        },
        state: { successMessage: "true" },
      });
    }
  } catch (err) {
    displayErrorMessage.value = i18n.global.t("errorMessage.generic");
    formState.value = "saveChanges";
  }
}

function disableForm() {
  return (
    !v$.value.$anyDirty ||
    formState.value === "saving" ||
    !store.hasComposerEnabledGatewayConnections() ||
    !store.hasPermission("organization.create_workflow")
  );
}
function formatPayload() {
  return {
    workflow: {
      name: state.name,
      sandbox: state.sandbox,
      environment_key: currentEnvironment.value.key,
      steps: [
        {
          conclusions: formatWorkflowConclusion(),
        },
      ],
    },
  };
}

const confirmSandboxToggle = (sandbox: boolean) => {
  confirm.require({
    group: "sandbox-warning",
    message: sandbox
      ? i18n.global.t("confirmations.workflowSandboxOn.message")
      : i18n.global.t("confirmations.workflowSandboxOff.message"),
    header: sandbox
      ? i18n.global.t("confirmations.workflowSandboxOn.header")
      : i18n.global.t("confirmations.workflowSandboxOff.header"),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: sandbox
      ? i18n.global.t("confirmations.workflowSandboxOn.enableSandbox")
      : i18n.global.t("confirmations.workflowSandboxOff.disableSandbox"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: () => populateState(),
    reject: () => (state.sandbox = !state.sandbox),
    onHide: () => (state.sandbox = !state.sandbox),
  });
};

function resetForm() {
  state.name = "";
  state.sandbox = false;
  v$.value.$reset();
  resetGatewayConclusion();
  resetChildrenForms.value = true;
  displayErrorMessage.value = null;
  submitted.value = false;
}
</script>
