<template>
  <SpreedlyContainer>
    <div
      v-if="currentOrganization"
      class="h-full"
      ref="container"
      :key="currentEnvironment.key"
    ></div>
  </SpreedlyContainer>
</template>

<script async setup lang="ts">
import * as Sentry from "@sentry/vue";
import { ref, watch } from "vue";
import { LookerEmbedSDK } from "@looker/embed-sdk";
import { onMounted } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";

const props = defineProps<{
  dashboardId?: number;
  exploreId?: string;
  orgView?: boolean;
}>();

const container = ref<HTMLInputElement | null>(null);
const { getAccessTokenSilently } = useAuth0();

const store = useSettingsStore();
const { currentOrganization, currentEnvironment } = storeToRefs(store);

watch(
  () => currentEnvironment.value.key,
  async () => {
    await loadLooker();
  }
);

onMounted(async () => {
  await loadLooker();
});

async function loadLooker() {
  const token = await getAccessTokenSilently();

  LookerEmbedSDK.init(import.meta.env.VITE_LOOKER_EMBED_URL, {
    url: `${import.meta.env.VITE_ID_URL}/api/looker/auth`,
    headers: [
      {
        name: "Authorization",
        value: token,
      },
    ],
    params: [
      {
        name: "org",
        value: currentOrganization.value.key,
      },
      {
        name: "env",
        value: currentEnvironment.value.key as string,
      },
      { name: "org_view", value: props.orgView.toString() },
    ],
  });

  if (container.value) {
    if (props.dashboardId) {
      LookerEmbedSDK.createDashboardWithId(props.dashboardId)
        .withDynamicIFrameHeight()
        .appendTo(container.value)
        .withClassName("w-full")
        .build()
        .connect()
        .catch((error: ErrorEvent) => {
          Sentry.captureException(error);
        });
    } else if (props.exploreId) {
      LookerEmbedSDK.createExploreWithId(props.exploreId)
        .appendTo(container.value)
        .withClassName("h-[100vh]")
        .withClassName("w-full")
        .build()
        .connect()
        .catch((error: ErrorEvent) => {
          Sentry.captureException(error);
        });
    }
  }

  return token;
}
</script>
