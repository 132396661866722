<template>
  <SpreedlyLookerEmbed
    id="looker-embed-account-updater"
    :dashboard-id="302"
    :org-view="props.orgView"
  />
</template>
<script setup lang="ts">
import SpreedlyLookerEmbed from "@/components/SpreedlyLookerEmbed.vue";

const props = defineProps<{
  orgView?: boolean;
}>();
</script>
