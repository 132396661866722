<template>
  <div>
    <SpreedlyPageTitle :title="$t('accessSecret.environment')">
      <template v-slot:info>
        <div
          class="flex flex-col md:flex-col lg:flex-row lg:justify-between -mt-6 text-spreedly-gray-600"
        >
          <i18n-t
            scope="global"
            :keypath="'infoMessage.accessSecret'"
            tag="p"
            class="text-caption"
          >
            <template v-slot:link>
              <router-link
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                :to="{ name: 'OrganizationAccessSecrets' }"
                >{{ $t("infoMessage.accessSecretLink") }}
              </router-link>
            </template>
          </i18n-t>
        </div>
      </template>
    </SpreedlyPageTitle>
    <AccessSecretList :environment="currentEnvironment" />
  </div>
</template>

<script setup lang="ts">
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";

import AccessSecretList from "@/components/AccessSecretList.vue";

import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";

const store = useSettingsStore();
const { currentEnvironment } = storeToRefs(store);
</script>
