<template>
  <div>
    <SpreedlyPageTitle :title="`${$t('report', 2)}`" tabs>
      <template v-slot:nav-tabs>
        <div>
          <button
            v-if="store.hasPermission('dashboard.read')"
            id="report-overview-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportEnvironmentOverview',
            }"
            @click="router.push({ name: 'ReportEnvironmentOverview' })"
          >
            {{ sentenceCase($t("overview")) }}
          </button>
          <button
            v-if="store.hasPermission('dashboard.read')"
            id="report-success-rates-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportSuccessRates',
            }"
            @click="router.push({ name: 'ReportSuccessRates' })"
          >
            {{ sentenceCase($t("successRates")) }}
          </button>
          <button
            v-if="store.hasPermission('dashboard.read')"
            id="report-failures-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportFailures',
            }"
            @click="router.push({ name: 'ReportFailures' })"
          >
            {{ sentenceCase($t("failures")) }}
          </button>
          <button
            v-if="store.hasPermission('dashboard.read')"
            id="report-transactions-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportTransactions',
            }"
            @click="router.push({ name: 'ReportTransactions' })"
          >
            {{ sentenceCase($t("transactions.transactions")) }}
          </button>
          <button
            v-if="store.hasPermission('dashboard.read')"
            id="report-3ds2-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'Report3DS2',
            }"
            @click="router.push({ name: 'Report3DS2' })"
          >
            {{ $t("3ds2") }}
          </button>
          <button
            v-if="store.hasPermission('dashboard.read')"
            id="report-payment-methods-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportPaymentMethods',
            }"
            @click="router.push({ name: 'ReportPaymentMethods' })"
          >
            {{ sentenceCase($t("payment_method", 2)) }}
          </button>
          <button
            v-if="
              store.hasPermission('dashboard.read') &&
              currentOrganization.allow_account_updater
            "
            id="report-routing-rules-retry-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportAccountUpdater',
            }"
            @click="router.push({ name: 'ReportAccountUpdater' })"
          >
            {{ $t("lifecycleManagement") }}
          </button>
          <button
            v-if="
              store.hasPermission('dashboard.read') &&
              store.hasAccessToRoutingRules
            "
            id="report-merchant-aggregator-button"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportRoutingRules',
            }"
            @click="router.push({ name: 'ReportRoutingRules' })"
          >
            {{ $t("recover") }}
          </button>
          <button
            v-if="store.hasPermission('dashboard.read')"
            class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
            :class="{
              '!text-spreedly-blue-600 !bg-spreedly-blue-300':
                $route.name === 'ReportMerchantAggregator',
            }"
            @click="router.push({ name: 'ReportMerchantAggregator' })"
          >
            {{ sentenceCase($t("merchantAggregator")) }}
          </button>
        </div>
      </template>
    </SpreedlyPageTitle>
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { useSettingsStore } from "@/stores/SettingsStore";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import { sentenceCase } from "@/services/HelperService";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

const store = useSettingsStore();
const { currentOrganization } = storeToRefs(store);

const router = useRouter();
</script>
