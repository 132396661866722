import http, { get, post } from "@/http";

export async function fetchStripeOauthRequestPath(
  orgKey: string,
  envKey: string,
  stripeTestEnvironmentEnabled: boolean
): Promise<string> {
  const url = `/organizations/${orgKey}/environments/${envKey}/fetch_stripe_oauth_request_path`;

  return post(
    url,
    {
      stripe_test_environment_enabled: stripeTestEnvironmentEnabled.toString(),
    },
    "stripe_oauth_request_path"
  );
}

export async function stripeConnectGatewaysList(
  orgKey: string,
  envKey: string
): Promise<string[]> {
  return get(
    `/organizations/${orgKey}/environments/${envKey}/gateways/stripe_connect_gateway_index/`,
    "gateways"
  );
}
export async function updateStripeEnvironment(
  orgKey: string,
  envKey: string,
  stripeAuthCode: string
): Promise<void> {
  const url = `/organizations/${orgKey}/environments/${envKey}/update_stripe_environment`;

  return await http().put(url, {
    stripe_auth_code: stripeAuthCode,
  });
}

export async function disconnectStripeEnvironment(
  orgKey: string,
  envKey: string
): Promise<void> {
  const url = `/organizations/${orgKey}/environments/${envKey}/disconnect_stripe_environment`;
  return await http().put(url);
}
