import { defineStore } from "pinia";
import { ref } from "vue";
import {
  getDefaultGateway,
  putDefaultGateway,
} from "@/services/routing-rules/RoutingRulesService";
import type { DefaultGatewayResponse } from "@/services/routing-rules/responses/DefaultGatewayResponse";
import type { DefaultGateway } from "@/models/routing-rules/default-gateway.model";

export const useDefaultGatewayStore = defineStore(
  "routing-rules-default-gateway",
  () => {
    const defaultGateway = ref<DefaultGateway>();
    const status = ref<string>("uninitialized");
    const message = ref<string | null>(null);

    function load(envKey: string, orgKey: string) {
      status.value = "loading";
      getDefaultGateway(envKey, orgKey).then((res: DefaultGatewayResponse) => {
        defaultGateway.value = res.data;
        status.value = res.status;
        message.value = res.message ?? null;
      });
    }

    function save(envKey: string, orgKey: string, newDefaultGateway: any) {
      status.value = "saving";
      putDefaultGateway(envKey, orgKey, newDefaultGateway).then((res: any) => {
        if (res.status === "ok") {
          defaultGateway.value = res.data;
        }
        status.value = res.status;
        message.value = res.message;
      });
    }

    return { load, save, defaultGateway, status, message };
  }
);
