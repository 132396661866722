<template>
  <fieldset form="ruleEditor" class="space-y-">
    <legend class="sr-only">transaction metadata</legend>
    <div v-for="(keyvalue, i) in metadata" :key="'metadata-' + i">
      <div class="w-full flex space-x-2 items-start">
        <div>
          <label
            :for="'metadataKey-' + (i + 1)"
            class="text-xs"
            :class="{
              'p-error':
                v$.$dirty &&
                v$.metadata.$each.$response.$errors[i]?.key.length > 0,
              'sr-only': i > 0,
            }"
          >
            {{ $t("routing.metadata.key") }}
            <span class="sr-only">{{ i + 1 }}</span>
          </label>
          <div>
            <InputText
              v-model="keyvalue.key"
              :style="{ width: 'auto' }"
              :id="'metadataKey-' + (i + 1)"
              form="ruleEditor"
            />
          </div>
        </div>
        <div class="grow">
          <label
            :for="'metadataValues-' + (i + 1)"
            class="text-xs"
            :class="{
              'p-error':
                v$.$dirty &&
                v$.metadata.$each.$response.$errors[i]?.values.length > 0,
              'sr-only': i > 0,
            }"
          >
            {{ $t("routing.metadata.values") }}
            <span class="sr-only">{{ i + 1 }}</span>
          </label>
          <Chips
            v-model="keyvalue.values"
            class="w-full"
            :inputId="'metadataValues-' + (i + 1)"
            form="ruleEditor"
          />
        </div>
        <div
          class="space-x-2 pt-2 whitespace-nowrap"
          :class="{ 'pt-8': i === 0 }"
        >
          <SpreedlyIconButton
            name="plus"
            size="xs"
            @click="add"
            class="h-6 w-6"
            inverse
            :aria-label="$t('routing.metadata.addMetadata')"
            :class="{ invisible: i < modelValue.length - 1 }"
            :title="$t('add')"
          />
          <SpreedlyIconButton
            name="minus"
            size="xs"
            @click="remove(i)"
            class="h-6 w-6"
            inverse
            :aria-label="$t('routing.metadata.removeMetadata') + (i + 1)"
            :title="$t('remove')"
          />
        </div>
      </div>
      <div>
        <SpreedlyErrorMessage
          v-if="v$.$dirty"
          class="mt-1"
          :errors="
            v$.metadata.$each.$response.$errors[i].key.concat(
              v$.metadata.$each.$response.$errors[i].values
            )
          "
        />
      </div>
      <div v-if="i < metadata.length - 1">
        <div
          class="py-2 text-sm text-center w-[calc(100%+4.5rem)] -ml-20"
          v-if="metadata.length > 1"
        >
          &mdash; {{ $t("and") }} &mdash;
        </div>
      </div>
      <div v-else class="pt-2 text-sm">
        {{ $t("routing.metadata.info") }};
        {{ $t("routing.metadata.valuesInstructions") }}.
      </div>
    </div>
  </fieldset>
</template>

<script lang="ts" setup>
import { watch, ref } from "vue";
import i18n from "@/i18n";
import InputText from "primevue/inputtext";
import Chips from "primevue/chips";
import SpreedlyErrorMessage from "@/components/SpreedlyErrorMessage.vue";
import SpreedlyIconButton from "@/components/SpreedlyIconButton.vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, maxLength, required } from "@vuelidate/validators";

const props = defineProps<{ modelValue: any }>();
const emit = defineEmits(["emptied"]);

const metadata = ref(
  props.modelValue.map((keyvalues) => {
    let key = null;
    const values = [];
    for (let x = 0; x < keyvalues.length; x += 1) {
      if (x === 0) {
        key = keyvalues[x].key;
      } else if (key !== keyvalues[x].key) {
        throw new Error("metadata keys are inconsistent in a valueset.");
      }
      values.push(keyvalues[x].value);
    }
    return { key, values };
  })
);

const add = () => {
  metadata.value.push({ key: "", values: [] });
};

const remove = (index: string) => {
  metadata.value.splice(index, 1);
};

watch(
  metadata,
  (mv) => {
    if (mv.length === 0) {
      emit("emptied");
    } else {
      const newFlattenedData = mv.map((keyvalue) =>
        keyvalue.values.map((value) => ({
          key: keyvalue.key,
          value,
        }))
      );
      // props.modelValue is read-only. So it can be modified, but not replaced.
      // as a result, upon every change, we have to replace its contents
      // with the flattened contents of the intermediate metadata object.
      props.modelValue.splice(0, props.modelValue.length, ...newFlattenedData);
    }
  },
  { deep: true }
);

const permittedCharacters = (value: string) => /^[a-z0-9_\- ]*$/.test(value);

const rules = {
  metadata: {
    $each: helpers.forEach({
      key: {
        required: helpers.withMessage(
          i18n.global.t("validations.metadata.typeRequired", { type: "Key" }),
          required
        ),
        maxLength: helpers.withMessage(
          i18n.global.t("validations.metadata.typeLength", {
            type: "Key",
            max: 50,
          }),
          maxLength(50)
        ),
        permittedCharacters: helpers.withMessage(
          i18n.global.t("validations.metadata.permittedCharacters"),
          permittedCharacters
        ),
      },
      values: {
        required: helpers.withMessage(
          i18n.global.t("validations.metadata.typeRequired", { type: "Value" }),
          required
        ),
        maxLength: helpers.withMessage(
          i18n.global.t("validations.metadata.typeLength", {
            type: "Each value",
            max: 500,
          }),
          (values: string[]) => values.every((value) => value.length <= 500)
        ),
        permittedCharacters: helpers.withMessage(
          i18n.global.t("validations.metadata.permittedCharacters"),
          (value: string[]) => permittedCharacters(value.join(""))
        ),
      },
    }),
  },
};

const v$ = useVuelidate(rules, { metadata: metadata });
</script>

<style>
.p-inputtext {
  margin-bottom: 0px !important;
}
</style>
