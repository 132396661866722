<template>
  <SpreedlyContainer class="h-[70vh]">
    <pre v-if="transcript">{{ transcript }}</pre>
    <span v-else>{{ $t("transactions.noTranscript") }}</span>
  </SpreedlyContainer>
</template>

<script lang="ts" setup>
import type { Transaction } from "@/services/TransactionService";
import { onMounted, ref } from "vue";
import { fetchTranscript } from "@/services/TransactionService";
import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import router from "@/router";

const store = useSettingsStore();
const { currentOrganization, currentEnvironment } = storeToRefs(store);

const transcript = ref();

const props = defineProps<{
  id: string;
  txn: Transaction;
}>();

onMounted(async () => {
  if (!props.txn.token) {
    await router.push({
      name: "TransactionDetailSummary",
      params: { id: props.id },
    });
  }
  await getTranscript();
});

async function getTranscript() {
  transcript.value = await fetchTranscript(
    currentOrganization.value.key,
    currentEnvironment.value.key as string,
    props.txn.token
  );
}
</script>
