import * as validators from "@vuelidate/validators";
import i18n from "@/i18n/index";

const { createI18nMessage, helpers } = validators;
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

export const required = withI18nMessage(validators.required);
export const requiredIf = withI18nMessage(validators.requiredIf, {
  withArguments: true,
});

export const roleLength = withI18nMessage(validators.minLength, {
  withArguments: true,
});

export const email = withI18nMessage(validators.email);

const isUtf8 = (value: string) => {
  if (value === "") return true;
  if (typeof value !== "string") return false;
  try {
    decodeURIComponent(escape(value));
    return true;
  } catch (e) {
    return false;
  }
};

export const utf8Validator = helpers.withMessage(
  i18n.global.t("validations.string"),
  isUtf8
);
