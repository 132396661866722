<template>
  <div v-if="workflow && workflow.key">
    <ConfirmDialog
      group="delete-workflow-warning"
      id="deleteDialog"
    ></ConfirmDialog>
    <ConfirmDialog group="sandbox-warning" id="warningDialog"></ConfirmDialog>
    <SpreedlyPageTitle
      :title="workflow.name"
      breadcrumbs
      :editable-title="formState !== 'saving'"
      :tag="defaultTag"
    >
      <template v-slot:edit-title>
        <SpreedlyEditableTitle
          :has-permission="store.hasPermission('workflow.update')"
          :required="true"
          v-model:title="workflow.name"
          v-model:title-update-successful="titleUpdateSuccessful"
          @update-title="updateTitle"
        ></SpreedlyEditableTitle>
      </template>

      <template v-slot:breadcrumbs>
        <router-link
          :to="{
            name: 'WorkflowList',
          }"
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
        >
          <i class="pi pi-arrow-left relative mr-1 pb-4 text-[0.75rem]"></i>
          {{ $t("composer.title") }}
        </router-link>
      </template>
    </SpreedlyPageTitle>
    <div class="w-full flex flex-row justify-between mb-2">
      <SpreedlySubtitle
        :title="$t('workflows.designer')"
        class="mb-4"
      ></SpreedlySubtitle>
    </div>
    <div class="flex flex-row w-full h-[calc(100vh-200px)]">
      <SpreedlyContainer class="h-full !mb-0 !pb-0 w-full overflow-y-hidden">
        <Message
          v-if="router.options.history.state.successMessage"
          class="-mx-8 -mt-8 w-[calc(100%+4rem)] justify-start"
          severity="success"
        >
          <i18n-t
            scope="global"
            :keypath="'successMessage.workflowCreated'"
            tag="p"
          >
            <template v-slot:workflow>
              <span class="font-bold">{{ workflow.name }}</span>
            </template>
          </i18n-t>
        </Message>
        <Message
          v-if="displayErrorMessage"
          class="-mx-8 -mt-8 w-[calc(100%+4rem)] justify-start"
          severity="error"
          :closable="false"
          >{{ displayErrorMessage }}
        </Message>

        <div class="flex flex-col justify-between h-full">
          <div class="flex flex-row overflow-y-auto h-full">
            <form
              class="relative"
              @submit.prevent="save(v.$invalid)"
              :class="width >= 800 ? 'w-[calc(100%-209.56px)]' : 'w-[95%]'"
            >
              <SpreedlyVerticalStepper :step-text="$t('workflows.start')">
                <div class="flex flex-col">
                  <div class="flex flex-row pb-2">
                    <span class="font-bold">{{
                      `"${workflow.name}" ${$t("key")}`
                    }}</span>
                    <div class="self-center ml-4">
                      <div
                        v-if="v.sandbox.$model"
                        class="border border-success-green bg-success-green-light text-spreedly-gray-600 rounded-xl px-2 mr-2 text-xs"
                      >
                        {{ $t("sandbox") }}
                      </div>
                    </div>
                  </div>

                  <SpreedlyCopyToClipboard
                    class="bg-spreedly-blue-100 rounded-lg !mb-2 lg:mb-4"
                    :token="workflow.key"
                  ></SpreedlyCopyToClipboard>
                  <div class="flex flex-row">
                    <div class="self-center">
                      <Checkbox
                        :disabled="!store.hasPermission('workflow.update')"
                        v-model="v.sandbox.$model"
                        input-id="workflow-sandbox-checkbox"
                        variant="outlined"
                        binary
                        @update:model-value="
                          confirmSandboxToggle(v.sandbox.$model)
                        "
                      />
                    </div>

                    <label
                      for="workflow-sandbox-checkbox"
                      class="self-center ml-2"
                    >
                      {{ $t("workflows.sandboxMode") }}
                    </label>
                    <mdicon
                      name="information-outline"
                      class="text-spreedly-blue-700 px-2 hover:cursor-pointer"
                      data-testid="info-icon"
                      v-tooltip="$t('workflows.sandboxModeInfo')"
                    ></mdicon>
                  </div>
                </div>
              </SpreedlyVerticalStepper>
              <SpreedlyVerticalStepper
                :step-text="$t('workflows.end')"
                :content-header="$t('gateway')"
              >
                <div v-if="gatewayConclusions && gatewayConclusions.length > 0">
                  <WorkflowGatewayConclusion
                    v-for="(gateway, index) in gatewayConclusions"
                    :key="gateway.uuid"
                    action="update"
                    :gateway="gateway"
                    :index="index"
                    :submitted="submitted"
                    v-model:reset="resetChildrenForms"
                    @update-gateway="updateGatewayConclusion"
                    @remove-split="removeGatewaySplit"
                  ></WorkflowGatewayConclusion>
                  <button
                    v-if="
                      gatewayConclusions.length === 1 &&
                      gateways.length > 1 &&
                      store.hasPermission('workflow.update')
                    "
                    type="button"
                    class="text-spreedly-blue-600 hover:text-spreedly-blue-700 add-gateway-split-button"
                    @click="addGatewaySplit()"
                  >
                    <i class="pi pi-plus relative mr-1 pb-4 text-[0.75rem]"></i>
                    {{ $t("workflows.addSplit") }}
                  </button>
                </div>
                <SpreedlyLoadingSpinner
                  v-else
                  :text="$t('workflows.loadingGatewayDefaults')"
                ></SpreedlyLoadingSpinner>
              </SpreedlyVerticalStepper>
            </form>
            <Button
              v-if="!togglePaymentCapabilities && width < 800"
              @click="togglePaymentCapabilities = true"
              :title="$t('viewCapabilities')"
              :aria-label="$t('viewCapabilities')"
              id="view-payment-capabilities"
              icon="pi pi-list"
              class="danger p-button-text p-button-rounded !shadow-none relative inset-y-0"
            ></Button>
            <div v-else-if="!togglePaymentCapabilities && width >= 800">
              <SpreedlyButton
                id="view-payment-capabilities"
                :text="$t('viewCapabilities')"
                inverse
                @click="togglePaymentCapabilities = true"
              ></SpreedlyButton>
            </div>
          </div>

          <div class="flex flex-row justify-between py-4">
            <div
              class="flex flex-shrink-0 flex-wrap items-center justify-start"
            >
              <SpreedlyButton
                class="mr-4"
                :text="$t('reset')"
                id="cancel-create-workflow-button"
                :inverse="true"
                :disabled="
                  !v.$anyDirty ||
                  formState === 'saving' ||
                  !store.hasPermission('workflow.update')
                "
                @click="resetForm()"
              ></SpreedlyButton>
              <SpreedlyButton
                :disabled="
                  !v.$anyDirty ||
                  formState === 'saving' ||
                  !store.hasPermission('workflow.update')
                "
                class="!mr-0"
                id="create-workflow-button"
                :icon="{ position: 'left', state: formState }"
                :text="$t(formState)"
                @click="save(v.$invalid)"
              ></SpreedlyButton>
            </div>
            <div class="flex flex-shrink-0 justify-end mt-4 items-center">
              <button
                v-if="store.hasPermission('workflow.delete')"
                :disabled="
                  workflow.key === currentEnvironment.default_workflow_key
                "
                v-tooltip.bottom="
                  workflow.key === currentEnvironment.default_workflow_key
                    ? $t('workflows.deleteTooltip')
                    : null
                "
                type="button"
                @click="confirmDeleteWorkflow"
                id="delete-workflow-button"
                class="px-4 py-2 max-h-[42px] inline-flex disabled:cursor-not-allowed justify-center items-center rounded whitespace-nowrap text-spreedly-blue-600 disabled:text-spreedly-gray-400 hover:text-spreedly-blue-700 cursor-pointer"
              >
                {{ $t("workflows.delete") }}
              </button>
            </div>
          </div>
        </div>
      </SpreedlyContainer>
      <PaymentCapabilitiesDrawer
        :composer="true"
        v-model:toggle-payment-capabilities="togglePaymentCapabilities"
      ></PaymentCapabilitiesDrawer>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlySubtitle from "@/components/SpreedlySubtitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import SpreedlyEditableTitle from "@/components/SpreedlyEditableTitle.vue";
import SpreedlyCopyToClipboard from "@/components/SpreedlyCopyToClipboard.vue";
import SpreedlyVerticalStepper from "@/components/SpreedlyVerticalStepper.vue";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";
import PaymentCapabilitiesDrawer from "@/components/PaymentCapabilitiesDrawer.vue";
import WorkflowGatewayConclusion from "@/components/WorkflowGatewayConclusion.vue";
import Message from "primevue/message";
import ConfirmDialog from "primevue/confirmdialog";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import {
  computed,
  onBeforeMount,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import i18n from "@/i18n";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import router from "@/router";
import { useVuelidate } from "@vuelidate/core";
import {
  deleteWorkflow,
  fetchWorkflow,
  updateWorkflow,
} from "@/services/WorkflowService";
import { onBeforeRouteLeave } from "vue-router";
import {
  addGatewaySplit,
  formatWorkflowConclusion,
  setGateways,
  removeSplit,
  resetGateways,
  setGatewayConclusions,
  updateGatewayConclusion,
  useGatewayConclusions,
} from "@/composables/useGatewayConclusions";
import { deepCopy } from "@/services/HelperService";
import { useConfirm } from "primevue/useconfirm";
import { useBreakpoints } from "@/composables/useBreakpoints";

const displayErrorMessage = ref<string | null>(null);
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const store = useSettingsStore();
const { currentEnvironment, currentOrganization } = storeToRefs(store);
const workflow = ref();
const titleUpdateSuccessful = ref<boolean | null>(null);
const resetChildrenForms = ref(false);
const { gatewayConclusions, gateways } = useGatewayConclusions();
const togglePaymentCapabilities = ref(false);
const props = defineProps<{
  id: string;
}>();
const confirm = useConfirm();
const { width } = useBreakpoints();

onBeforeMount(async () => {
  await getWorkflow(true);
});

onUnmounted(() => {
  resetGateways();
});

onBeforeRouteLeave((to, from) => {
  if (v.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

async function getWorkflow(showSuccessBanner?: boolean) {
  if (!showSuccessBanner) router.options.history.state.successMessage = null;

  try {
    workflow.value = await fetchWorkflow(
      currentOrganization.value.key,
      currentEnvironment.value.key as string,
      props.id
    );
  } catch (error) {
    // No workflow returned (it was probably deleted)
    await router.push({ name: "WorkflowList" });
  }

  if (workflow.value && workflow.value.key) {
    state.sandbox = workflow.value.sandbox;
    await populateState();
  } else {
    displayErrorMessage.value = i18n.global.t("errorMessage.workflowLoad", {
      workflowKey: props.id,
    });
  }
}

const confirmDeleteWorkflow = () => {
  confirm.require({
    group: "delete-workflow-warning",
    message: i18n.global.t("confirmations.deleteWorkflow.message"),
    header: i18n.global.t("confirmations.deleteWorkflow.header", {
      workflowName: workflow.value.name,
    }),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("workflows.delete"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: async () => {
      if (
        await deleteWorkflow(
          currentOrganization.value.key,
          currentEnvironment.value.key as string,
          workflow.value.key
        )
      ) {
        await router.push({ name: "WorkflowList" });
      }
    },
  });
};

const submitted = ref(false);
const state = reactive({
  sandbox: false,
});

const rules = {
  sandbox: {},
};
const v = useVuelidate(rules, state);

async function populateState() {
  await setGateways(
    currentOrganization.value.key,
    currentEnvironment.value.key!,
    state.sandbox
  );
  setGatewayConclusions(deepCopy(workflow.value));
}

const removeGatewaySplit = (index: number) => {
  removeSplit(index);
  v.value.$touch();
};

const defaultTag = computed(() => {
  return {
    displayTag:
      currentEnvironment.value.default_workflow_key === workflow.value.key,
    severity: "composer",
    value: i18n.global.t("workflows.default"),
  };
});

watch(
  () => v.value.$anyDirty,
  () => {
    if (v.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

watch(currentEnvironment, () => {
  router.push({
    name: "WorkflowList",
  });
});

const updateTitle = async (title: string) => {
  const response = await updateWorkflow(
    currentOrganization.value.key,
    currentEnvironment.value.key as string,
    workflow.value.key,
    { workflow: { name: title } }
  );
  if (response) {
    await getWorkflow();
    titleUpdateSuccessful.value = true;
  }
};

async function save(isFormInvalid: boolean) {
  if (!v.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = null;

  if (!store.hasPermission("workflow.update")) {
    displayErrorMessage.value = i18n.global.t("permission_denied_edit");
    return;
  }
  submitted.value = true;

  if (isFormInvalid) {
    return;
  }

  try {
    formState.value = "saving";
    await updateWorkflow(
      currentOrganization.value.key,
      currentEnvironment.value.key as string,
      workflow.value.key,
      formatPayload()
    );
    formState.value = "saved";
    v.value.$reset();
    await getWorkflow();
  } catch (err) {
    displayErrorMessage.value = i18n.global.t("errorMessage.generic");
    formState.value = "saveChanges";
  }
}

function formatPayload() {
  return {
    workflow: {
      name: workflow.value.name,
      sandbox: state.sandbox,
      steps: [
        {
          conclusions: formatWorkflowConclusion(),
        },
      ],
    },
  };
}

const confirmSandboxToggle = (sandbox: boolean) => {
  confirm.require({
    group: "sandbox-warning",
    message: sandbox
      ? i18n.global.t("confirmations.workflowSandboxOn.message")
      : i18n.global.t("confirmations.workflowSandboxOff.message"),
    header: sandbox
      ? i18n.global.t("confirmations.workflowSandboxOn.header")
      : i18n.global.t("confirmations.workflowSandboxOff.header"),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: sandbox
      ? i18n.global.t("confirmations.workflowSandboxOn.enableSandbox")
      : i18n.global.t("confirmations.workflowSandboxOff.disableSandbox"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: () => populateState(),
    reject: () => (state.sandbox = !state.sandbox),
    onHide: () => (state.sandbox = !state.sandbox),
  });
};

async function resetForm() {
  v.value.$reset();
  state.sandbox = workflow.value.sandbox;
  await populateState();
  resetChildrenForms.value = true;
  displayErrorMessage.value = null;
  submitted.value = false;
}
</script>
