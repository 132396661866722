import {
  mdiArrowDown,
  mdiArrowUp,
  mdiBookmark,
  mdiBookmarkCheck,
  mdiCallSplit,
  mdiChartBar,
  mdiChartLine,
  mdiCheck,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronUp,
  mdiClose,
  mdiCog,
  mdiCogOutline,
  mdiConnection,
  mdiContentCopy,
  mdiDelete,
  mdiDeleteRestore,
  mdiDomain,
  mdiDotsCircle,
  mdiDownload,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFileDocumentMultiple,
  mdiFormatListBulleted,
  mdiGrid,
  mdiHomeOutline,
  mdiInformationOutline,
  mdiInformation,
  mdiKey,
  mdiMinus,
  mdiOpenInNew,
  mdiPencil,
  mdiPlus,
  mdiRefresh,
  mdiShieldKeyOutline,
  mdiSort,
  mdiSpeedometer,
  mdiStar,
  mdiMagnify,
  mdiTransitConnectionVariant,
} from "@mdi/js";
import { toName } from "mdi-vue/src/shared";

/**
 * Available Icons from https://pictogrammers.com/library/mdi/
 *
 * Add new icons that are used to the import above and here.
 */
const icons = {
  mdiArrowDown,
  mdiArrowUp,
  mdiBookmark,
  mdiBookmarkCheck,
  mdiCallSplit,
  mdiChartBar,
  mdiChartLine,
  mdiCheck,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronUp,
  mdiClose,
  mdiCog,
  mdiCogOutline,
  mdiConnection,
  mdiContentCopy,
  mdiDelete,
  mdiDeleteRestore,
  mdiDomain,
  mdiDotsCircle,
  mdiDownload,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFileDocumentMultiple,
  mdiFormatListBulleted,
  mdiGrid,
  mdiHomeOutline,
  mdiInformationOutline,
  mdiInformation,
  mdiKey,
  mdiMagnify,
  mdiMinus,
  mdiOpenInNew,
  mdiPencil,
  mdiPlus,
  mdiRefresh,
  mdiShieldKeyOutline,
  mdiSort,
  mdiSpeedometer,
  mdiStar,
  mdiTransitConnectionVariant,
};

export function isIconImported(name: string) {
  const svgName = "mdi" + toName(name);
  if (svgName in icons) return true;
  console.warn(
    `No icon found for "${name}". Import "${svgName}" in the Icon Service.`
  );
  return false;
}

export default icons;
