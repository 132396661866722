import { defineStore } from "pinia";
import { ref } from "vue";
import {
  getRules,
  putRules,
} from "@/services/routing-rules/RoutingRulesService";
import type { RulesResponse } from "@/services/routing-rules/responses/RulesResponse";
import type { Rule } from "@/models/routing-rules/rule.model";
import { v4 as uuidv4 } from "uuid";
import { deepCopy } from "@/services/HelperService";

export const useRulesStore = defineStore(
  "routing-rules-transaction-rules",
  () => {
    const rules = ref<Rule[]>();
    const editedRules = ref<Rule[]>();
    const status = ref<string>("uninitialized");
    const message = ref<string>();

    const addUiKeys = (rules: any) => {
      return rules.map((rule: any) => {
        rule.ui_key = rule.ui_key ?? uuidv4();
        return rule;
      });
    };

    function load(envKey: string, orgKey: string) {
      status.value = "loading";
      getRules(envKey, orgKey).then((res: RulesResponse) => {
        rules.value = res.status === "ok" ? res.data ?? [] : undefined;
        editedRules.value = deepCopy(addUiKeys(rules.value));
        message.value = res.message;
        status.value = res.status;
      });
    }

    function save(envKey: string, orgKey: string, newDefaultGateway: any) {
      status.value = "saving";
      putRules(envKey, orgKey, newDefaultGateway).then((res: RulesResponse) => {
        if (res.status === "ok") {
          rules.value = res.data;
          editedRules.value = deepCopy(addUiKeys(rules.value));
        }
        message.value = res.message;
        status.value = res.status;
      });
    }

    return { load, save, rules, editedRules, status, message };
  }
);
