<template>
  <SpreedlyLookerEmbed
    :dashboard-id="7"
    id="looker-embed-transaction-success-rates"
    :org-view="props.orgView"
  />
</template>
<script setup lang="ts">
import SpreedlyLookerEmbed from "@/components/SpreedlyLookerEmbed.vue";
const props = defineProps<{
  orgView?: boolean;
}>();
</script>
