import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

export function deepCopy(obj: unknown) {
  if (obj) {
    return JSON.parse(JSON.stringify(obj));
  }
}

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
}

export function sentenceCase(str: string) {
  if (str && str.trim()) {
    str = str.toLowerCase().trim();
    str = str.replaceAll("_", " ");
    return str.replace(/[a-z]/i, (letter: string) => {
      return letter.toUpperCase();
    });
  }
  return str;
}

export function truncateDisplayValue(str?: string) {
  if (str) {
    const sub = str.substring(0, 8);
    return sub.concat("...");
  }
  return str;
}

export function formatDate(date: string) {
  if (date) {
    dayjs.extend(localizedFormat);
    return dayjs(date).format("lll");
  }
  return date;
}

export function isEqual(obj1: unknown, obj2: unknown) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
