<template>
  <mdicon :name="name" :spin="spin" :size="size" :data-testid="testId"></mdicon>
</template>
<script setup lang="ts">
import { isIconImported } from "@/services/IconService";

const props = defineProps({
  name: {
    type: String,
    required: true,
    validator: isIconImported,
  },
  size: Number,
  spin: Boolean,
});

const testId = `spreedly-icon-${props.name}`;
</script>
