import i18n from "@/i18n";
import { useOptionsStore } from "@/stores/routing-rules/OptionsStore";

function standardRetryModeMessage() {
  const gws = useOptionsStore().options.retryable_gateways.join(", ");
  return `${i18n.global.t("validations.standardRetryModeUnsupported")}${gws}`;
}

function validateRetryMode(mode: string, gatewayTypes: (string | null)[]) {
  switch (mode) {
    case null:
      return true;
    case "outage_only":
      return true;
    case "standard":
      return gatewayTypes.some((type) =>
        useOptionsStore().options.retryable_gateways.includes(type)
      );
  }
}

export { validateRetryMode, standardRetryModeMessage };
