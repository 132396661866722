<template>
  <div>
    <h2 class="text-spreedly-gray-700 text-xl lg:text-3xl mb-4">
      {{ $t("routing.defaultGateway.defaultGateway") }}
    </h2>
    <Message v-if="status === 'error'" severity="error">
      {{ $t("errorMessage.somethingWentWrong") }}
    </Message>
    <Message v-if="saveWasOk" severity="success" @close="saveWasOk = null">
      {{ $t("successMessage.generic") }}
    </Message>
    <Message
      v-if="options.gateways?.length === 0"
      severity="warn"
      :closable="false"
    >
      {{ $t("routing.gateway.noGatewaysAvailable") }}
    </Message>
    <div v-else-if="defaultGateway" class="space-y-4">
      <div v-if="!defaultGateway.key">
        <strong>{{ $t("routing.defaultGateway.noneSelected") }}</strong
        >&nbsp;
        <span>{{ $t("routing.defaultGateway.required") }}</span>
      </div>
      <div v-else class="space-y-4">
        <p :key="defaultGateway.key">
          <GatewayDescription
            :gateway="findGateway(gatewayList, defaultGateway.key)"
            size="xl"
          />
        </p>
        <p v-if="defaultGateway.retry?.gateway_tokens?.length > 0">
          <RetryDescription v-model="defaultGateway.retry" />
        </p>
      </div>
      <div class="flex justify-end">
        <SpreedlyButton
          :text="defaultGateway.key ? $t('change') : $t('select')"
          @click="displayModal = true"
        >
        </SpreedlyButton>
      </div>
    </div>
    <div
      v-else-if="status === 'loading' || status === 'saving'"
      class="text-center"
    >
      <SpreedlyLoadingSpinner />
    </div>
    <Dialog
      v-model:visible="displayModal"
      :draggable="false"
      :class="{ 'p-dialog-maximized': width < 480 }"
      :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
      :style="{ width: '50vw' }"
      :close-on-escape="true"
      @close="displayModal = false"
      modal
    >
      <template #header>
        <h1 class="text-xl font-medium leading-normal text-spreedly-gray-700">
          {{ $t("routing.defaultGateway.set") }}
        </h1>
      </template>
      <p class="text-sm mb-4">
        {{ $t("routing.defaultGateway.required") }}
        {{ $t("routing.defaultGateway.info") }}
      </p>
      <div class="space-y-3">
        <div class="field">
          <div>
            <span
              :class="{ 'p-error': v$.key.$errors.length > 0 }"
              id="dd-default-gateway"
              >{{ $t("routing.defaultGateway.defaultGateway") }}
            </span>
            <Dropdown
              v-model="selectedDefaultGateway.key"
              :options="selectOptions(options?.gateways!)"
              :aria-labelledby="'dd-default-gateway'"
              name="defaultGateway"
              option-label="label"
              option-value="value"
              :placeholder="$t('routing.defaultGateway.select')"
            />
            <SpreedlyErrorMessage
              class="-mt-4"
              v-if="v$.key.$errors"
              :errors="v$.key.$errors"
            />
          </div>
        </div>
        <div>
          <Retry
            v-model="selectedDefaultGateway.retry"
            :targetData="selectedDefaultGateway.key"
          />
        </div>
      </div>
      <template #footer>
        <SpreedlyButton
          :text="$t('cancel')"
          @click="displayModal = false"
          inverse
        >
        </SpreedlyButton>
        <SpreedlyButton :text="$t('save')" @click="save"> </SpreedlyButton>
      </template>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, watch } from "vue";
import { deepCopy } from "@/services/HelperService";

import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import i18n from "@/i18n";
import { storeToRefs } from "pinia";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import GatewayDescription from "@/components/RoutingGatewayDescription.vue";
import RetryDescription from "@/components/RoutingRetryDescription.vue";
import SpreedlyErrorMessage from "@/components/SpreedlyErrorMessage.vue";
import Retry from "@/components/RoutingRetry.vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { useSettingsStore } from "@/stores/SettingsStore";
import { useOptionsStore } from "@/stores/routing-rules/OptionsStore";
import { useDefaultGatewayStore } from "@/stores/routing-rules/DefaultGatewayStore";
import { useBreakpoints } from "@/composables/useBreakpoints";
import Message from "primevue/message";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";
import { findGateway, selectOptions } from "@/services/routing-rules/gateways";
import type { Gateway } from "@/models/routing-rules/gateway.model";

const { width } = useBreakpoints();
const defaultGatewayStore = useDefaultGatewayStore();
const { defaultGateway, status } = storeToRefs(defaultGatewayStore);
const store = useSettingsStore();
const { options } = useOptionsStore();
const displayModal = ref(false);
const selectedDefaultGateway = reactive({
  key: null,
  retry: { mode: null, gateway_tokens: [] },
});

const saveWasOk = ref();

const rules = {
  key: {
    required: helpers.withMessage(
      i18n.global.t("validations.defaultGatewayKeyRequired"),
      required
    ),
  },
};
const v$ = useVuelidate(rules, selectedDefaultGateway);

const updateSelectedDefaultGateway = () => {
  if (defaultGateway.value && defaultGateway.value.key) {
    selectedDefaultGateway.key = defaultGateway.value.key;
    selectedDefaultGateway.retry = deepCopy(defaultGateway.value?.retry);
  }
};

const gatewayList: Gateway[] = options?.gateways ?? [];

const save = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) {
    return;
  }

  defaultGatewayStore.save(
    store.currentEnvironment.key as string,
    store.currentOrganization.key,
    JSON.parse(JSON.stringify(selectedDefaultGateway))
  );
  displayModal.value = false;
};

watch(status, (newVal, oldVal) => {
  saveWasOk.value = newVal === "ok" && oldVal === "saving";
});

watch(displayModal, () => {
  if (displayModal.value === false) {
    v$.value.$reset();
  }
  if (displayModal.value === true && status.value !== "error") {
    updateSelectedDefaultGateway();
  }
});
</script>
