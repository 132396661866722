import { createI18n } from "vue-i18n";
import messages from "@intlify/unplugin-vue-i18n/messages";

export default createI18n<false>({
  locale: window.navigator.language,
  legacy: false,
  globalInjection: true,
  fallbackLocale: "en-US",
  availableLocales: ["en-US", "es-MX"],
  messages,
});
