<template>
  <fieldset form="ruleEditor">
    <legend class="sr-only">bin data</legend>
    <div class="space-y-4">
      <div v-for="(binRange, i) in modelValue" :key="'bin_lower-' + i">
        <div class="flex items-center space-x-2">
          <div class="grow">
            <label
              class="text-xs"
              :for="'binLower-' + i"
              :class="{ 'sr-only': i > 0 }"
            >
              {{ $t("routing.binRanges.lowerLabel") }}
              <span class="sr-only">&nbsp;{{ i + 1 }}</span>
            </label>
            <InputText
              :id="'binLower-' + i"
              v-model="binRange.bin_lower"
              maxlength="6"
              @keydown="restrictInput"
            />
          </div>
          <div :class="{ 'pt-5': i === 0 }">&nbsp;&mdash;&nbsp;</div>
          <div class="grow">
            <label
              class="text-xs"
              :for="'binUpper-' + i"
              :class="{ 'sr-only': i > 0 }"
            >
              {{ $t("routing.binRanges.upperLabel") }}
              <span class="sr-only">&nbsp;{{ i + 1 }}</span>
            </label>
            <InputText
              :id="'binUpper-' + i"
              v-model="binRange.bin_upper"
              maxlength="6"
              @keydown="restrictInput"
            />
          </div>
          <div
            class="space-x-2 pt-2 whitespace-nowrap"
            :class="{ 'pt-8': i === 0 }"
          >
            <SpreedlyIconButton
              name="plus"
              size="xs"
              @click="add"
              class="h-6 w-6"
              inverse
              :aria-label="$t('routing.binRanges.addBinRange')"
              :class="{ invisible: i < modelValue.length - 1 }"
              :title="$t('add')"
            />
            <SpreedlyIconButton
              name="minus"
              size="xs"
              @click="remove(i)"
              class="h-6 w-6"
              inverse
              :aria-label="$t('routing.binRanges.removeBinRange') + (i + 1)"
              :title="$t('remove')"
            />
          </div>
        </div>
        <div>
          <SpreedlyErrorMessage
            v-if="v$.binRanges.$dirty"
            class="mt-1"
            :errors="
              v$.binRanges.$each.$response.$errors[i].bin_lower.concat(
                v$.binRanges.$each.$response.$errors[i].bin_upper
              )
            "
          />
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script lang="ts" setup>
import i18n from "@/i18n";
import InputText from "primevue/inputtext";
import SpreedlyErrorMessage from "./SpreedlyErrorMessage.vue";
import SpreedlyIconButton from "@/components/SpreedlyIconButton.vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";

const emit = defineEmits(["emptied"]);

const props = defineProps<{ modelValue: any }>();
const add = () => {
  props.modelValue.push({
    bin_lower: "",
    bin_upper: "",
  });
};

const remove = (index: number) => {
  props.modelValue.splice(index, 1);
  if (props.modelValue.length === 0) {
    emit("emptied");
  }
};

// validation
const rules = {
  binRanges: {
    $each: helpers.forEach({
      bin_lower: {
        required: helpers.withMessage(
          i18n.global.t("validations.binRanges.required"),
          required
        ),
        minLength: helpers.withMessage(
          i18n.global.t("validations.binRanges.minLength6"),
          minLength(6)
        ),
      },
      bin_upper: {
        required: helpers.withMessage(
          i18n.global.t("validations.binRanges.required"),
          required
        ),
        minLength: helpers.withMessage(
          i18n.global.t("validations.binRanges.minLength6"),
          minLength(6)
        ),
        exceedsOrEquals: helpers.withMessage(
          i18n.global.t("validations.binRanges.binUpperMustBeGreater"),
          (binUpperValue: number, binRange) => {
            return Number(binUpperValue) >= Number(binRange.bin_lower);
          }
        ),
      },
    }),
  },
};
const v$ = useVuelidate(rules, { binRanges: props.modelValue });

const restrictInput = (event: KeyboardEvent) => {
  // Allow only numeric characters (0-9) and certain control keys (e.g., backspace) on keypresses
  if (
    !/^\d+$/.test(event.key) &&
    !["Backspace", "ArrowLeft", "ArrowRight", "Tab"].includes(event.key) &&
    !(event.shiftKey && event.key === "Tab")
  ) {
    event.preventDefault();
  }
};
</script>
