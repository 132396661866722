import axios from "axios";
import * as Sentry from "@sentry/vue";
import type {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestHeaders,
} from "axios";
import { auth0 } from "@/plugins/createAuth0";

export const http = (options = {}) => {
  options = {
    baseURL: import.meta.env.VITE_ID_URL + "/api",
    headers: {
      "Content-type": "application/json",
    },
    ...options,
  };

  const responseHandler = (response: AxiosResponse) => {
    //TODO: can possibly add switch case to handle different response status, example: 403 display forbidden toast
    return response;
  };
  const errorHandler = (error: AxiosError) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  };

  const client: AxiosInstance = axios.create(options);

  client.interceptors.response.use(responseHandler, errorHandler);
  client.interceptors.request.use(async (config) => {
    config.headers ||= {} as AxiosRequestHeaders;
    config.headers[
      "Authorization"
    ] = `Bearer ${await auth0.getAccessTokenSilently()}`;
    return config;
  });

  return client;
};

export async function get<Response>(
  url: string,
  prop?: string
): Promise<Response> {
  const response = await http().get<
    Response | { [prop: string]: Response },
    AxiosResponse
  >(url);
  if (prop) {
    return (response.data as Record<string, any>)[prop];
  } else {
    return response.data;
  }
}

export async function post<Response>(
  url: string,
  body?: unknown,
  prop?: string
): Promise<Response> {
  const response = await http().post<
    Response | { [prop: string]: Response },
    AxiosResponse
  >(url, body);
  if (prop) {
    return (response.data as Record<string, any>)[prop];
  } else {
    return response.data;
  }
}

export async function put<Response>(
  url: string,
  body: unknown,
  prop?: string
): Promise<Response> {
  const response = await http().put<
    Response | { [prop: string]: Response },
    AxiosResponse
  >(url, body);
  if (prop) {
    return (response.data as Record<string, any>)[prop];
  } else {
    return response.data;
  }
}

export async function getList<Response>(
  url: string,
  prop?: string
): Promise<Response[]> {
  return get<Response[]>(url, prop).then((list) => list || []);
}

export default http;
