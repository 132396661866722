import { http, getList, post } from "@/http";

export interface AccessSecret {
  name: string;
  key: string;
  created_at: string;
  access_secret: string;
}

export async function fetchAccessSecrets(
  orgKey: string,
  envKey?: string
): Promise<AccessSecret[]> {
  const url = buildAccessSecretBaseUrl(orgKey, envKey);
  return getList<AccessSecret>(url, "access_secrets");
}

export async function generateAccessSecret(
  name: string,
  orgKey: string,
  envKey?: string
): Promise<AccessSecret> {
  return post(
    buildAccessSecretBaseUrl(orgKey, envKey),
    { name },
    "application"
  );
}

export async function deleteAccessSecret(
  orgKey: string,
  accessKey: string,
  envKey?: string
): Promise<void> {
  const url = `${buildAccessSecretBaseUrl(orgKey, envKey)}/${accessKey}`;
  await http().delete(url);
}

function buildAccessSecretBaseUrl(orgKey: string, envKey?: string) {
  if (envKey) {
    return `/organizations/${orgKey}/environments/${envKey}/applications`;
  }
  return `/organizations/${orgKey}/applications`;
}
