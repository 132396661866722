<template>
  <div>
    <component
      :is="unique_messages.length > 1 ? 'ul' : 'div'"
      v-for="(message, index) of unique_messages"
      :key="index"
      class="p-error text-sm"
      :class="{
        'list-disc ml-3': unique_messages.length > 1,
      }"
    >
      <component :is="unique_messages.length > 1 ? 'li' : 'span'">
        {{ message }}
      </component>
    </component>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  errors: {
    type: Array,
    required: true,
  },
});

const unique_messages = computed(() => {
  const messages = props.errors.map((err: any) => err.$message);
  return messages.filter((item, i, arr) => arr.indexOf(item) === i);
});
</script>
